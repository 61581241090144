// Copyright: (c) 2020-2021, VTK Gent vzw
// GNU Affero General Public License v3.0+ (see COPYING or https://www.gnu.org/licenses/agpl-3.0.txt)

import { ApolloHelpers } from '@nuxtjs/apollo'
import { Auth, ExpiredAuthSessionError } from '@nuxtjs/auth-next/dist/runtime'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, FetchResult, fromPromise, Observable, toPromise } from 'apollo-link'
import { DocumentNode } from 'graphql'

import type { CasScheme } from '~/auth/types'

export class CasHandler {
  public readonly $auth: Auth

  private readonly $apolloClient: ApolloClient<any>
  private readonly $apolloHelpers: ApolloHelpers
  private originalLink?: ApolloLink

  constructor($auth: Auth) {
    // Set $auth
    this.$auth = $auth
    // Set Apollo property shortcuts
    this.$apolloClient = $auth.ctx.app.apolloProvider.defaultClient
    this.$apolloHelpers = $auth.ctx.app.$apolloHelpers
  }

  async setHeader(token: string): Promise<void> {
    // Remove token type
    token = token.split(' ', 2)[1]
    // Set Authorization token for all Apollo requests
    await this.$apolloHelpers.onLogin(token, this.$apolloClient, {}, true)
  }

  async clearHeader(): Promise<void> {
    // Clear authorization token for all Apollo requests
    // @ts-ignore TODO: remove ts-ignore once types are up-to-date
    await this.$apolloHelpers.onLogout(this.$apolloClient, true)
  }

  initializeApolloLink(refresh: DocumentNode): void {
    // Return if the link was already set up
    if (this.originalLink !== undefined) return

    // Create new Apollo link
    this.originalLink = this.$apolloClient.link
    const link = ApolloLink.from([
      new ApolloLink((operation, forward): Observable<FetchResult> => {
        // Don't intercept refresh token requests
        const names = refresh.definitions.flatMap((d) => 'name' in d && d.name?.value)
        const isRefresh = names.includes(operation.operationName)
        if (isRefresh) return forward(operation)

        // Perform scheme checks.
        const { valid, tokenExpired, refreshTokenExpired, isRefreshable } = this.$auth.check(true)
        const isValid = valid

        // Refresh token has expired. There is no way to refresh. Force reset.
        if (refreshTokenExpired) {
          this.$auth.reset()
          this.$auth.callOnError(new ExpiredAuthSessionError(), {})
          throw new ExpiredAuthSessionError()
        }

        // Token has expired.
        if (tokenExpired) {
          // Refresh token is not available. Force reset.
          if (!isRefreshable) {
            this.$auth.reset()
            this.$auth.callOnError(new ExpiredAuthSessionError(), {})
            throw new ExpiredAuthSessionError()
          }

          // Refresh token is available. Attempt refresh.
          return fromPromise(
            this.$auth
              .refreshTokens()
              .then(() => {
                // Token is valid, let the request pass
                // Fetch updated token and add to current request
                return toPromise(forward(operation))
              })
              .catch(() => {
                // Tokens couldn't be refreshed. Force reset.
                this.$auth.reset()
                this.$auth.callOnError(new ExpiredAuthSessionError(), {})
                throw new ExpiredAuthSessionError()
              })
          )
        }

        // Sync token
        const token = (this.$auth.getStrategy() as CasScheme).token.get()

        // Scheme checks were performed, but returned that is not valid.
        if (!isValid) {
          // The authorization header in the current request is expired.
          // Token was deleted right before this request
          if (!token && !!operation.getContext()?.headers?.Authorization) {
            this.$auth.callOnError(new ExpiredAuthSessionError(), {})
            throw new ExpiredAuthSessionError()
          }

          return forward(operation)
        }

        return forward(operation)
      }),
      this.$apolloClient.link,
    ])
    // Modify the client of the scheme to use the new link
    this.$apolloClient.link = link
    this.$apolloClient.queryManager.link = link
  }

  reset(): void {
    // Return if the link was not set up yet
    if (this.originalLink === undefined) return

    // Modify the client of the scheme to use the old link
    this.$apolloClient.link = this.originalLink
    this.$apolloClient.queryManager.link = this.originalLink
    this.originalLink = undefined
  }
}
