import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: app.$config.gtagId,
        deferScriptLoad: true, // load script only when the rest of the page is done loading
        params: {
          anonymize_ip: true, // anonymize IP addresses
          send_page_view: false, // avoid duplicate page track on page reload
        },
      },
      enabled: false,
    },
    app.router
  )
}
