import gql from 'graphql-tag'
import * as VueApolloComposable from '@vue/apollo-composable'
import type * as VueCompositionApi from '@vue/composition-api'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type ReactiveFunction<TParam> = () => TParam
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any
}

/** An enumeration. */
export enum CourseOfferingSemester {
  /** 1st */
  A_1 = 'A_1',
  /** 2nd */
  A_2 = 'A_2',
  /** Year */
  J = 'J',
}

export type CourseDescriptionVersionChangesFragment = {
  __typename?: 'CourseDescriptionVersionType'
  id: string
  changes?: Maybe<string>
}

export type CourseDescriptionVersionContentFragment = {
  __typename?: 'CourseDescriptionVersionType'
  id: string
  content?: Maybe<string>
}

export type CourseDescriptionVersionFragment = {
  __typename?: 'CourseDescriptionVersionType'
  id: string
  number: number
  changesAddedAmount?: Maybe<number>
  changesDeletedAmount?: Maybe<number>
  revision?: Maybe<{
    __typename?: 'RevisionType'
    dateCreated: any
    user?: Maybe<{ __typename?: 'UserType'; firstName: string; lastName: string }>
  }>
  revertedVersion?: Maybe<{ __typename?: 'CourseDescriptionVersionType'; number: number }>
}

export type CourseDetailFragment = {
  __typename?: 'CourseType'
  id: string
  code: string
  name?: Maybe<string>
  subscribed?: Maybe<boolean>
  instructor?: Maybe<{ __typename?: 'EmployeeType'; name: string }>
  description: {
    __typename?: 'CourseDescriptionType'
    content: string
    lastVersionDate?: Maybe<string>
    versionsAmount?: Maybe<number>
    lastVersionUser?: Maybe<{ __typename?: 'UserType'; firstName: string; lastName: string; username: string }>
  }
}

export type CourseDocumentFragment = {
  __typename?: 'CourseType'
  id: string
  documents: {
    __typename?: 'DocumentTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'DocumentTypeEdge'; node?: Maybe<{ __typename?: 'DocumentType' } & DocumentInfoFragment> }>
    >
  }
}

export type CourseFragment = {
  __typename?: 'CourseType'
  id: string
  code: string
  name?: Maybe<string>
  subscribed?: Maybe<boolean>
  instructor?: Maybe<{ __typename?: 'EmployeeType'; name: string }>
}

export type DocumentInfoFragment = {
  __typename?: 'DocumentType'
  id: string
  name: string
  pageCount?: Maybe<number>
  downloadCount: number
  uploadDate: any
  lastUpdated: any
  file: string
  anonymous: boolean
  averageRatings?: Maybe<number>
  myRating?: Maybe<string>
  ratingsCount?: Maybe<number>
  author?: Maybe<{ __typename?: 'UserType'; firstName: string; lastName: string; username: string }>
  tags: {
    __typename?: 'DocumentTagTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'DocumentTagTypeEdge'; node?: Maybe<{ __typename?: 'DocumentTagType'; name: string }> }>
    >
  }
}

export type FacultyInfoFragment = { __typename?: 'FacultyType'; code?: Maybe<string>; name?: Maybe<string> }

export type ProgramInfoFragment = { __typename?: 'ProgramType'; code: string; name?: Maybe<string> }

export type StatisticsComparisonFragment = {
  __typename?: 'ComparisonStatisticsType'
  studentSociety?: Maybe<string>
  documentsUploaded?: Maybe<number>
}

export type StatisticsTotalFragment = {
  __typename?: 'TotalStatisticsType'
  totalUsersCount?: Maybe<number>
  totalDocumentsCount?: Maybe<number>
}

export type StatisticsWeekFragment = {
  __typename?: 'StatisticsType'
  periodNumber?: Maybe<number>
  newDocumentsCount?: Maybe<number>
  newUsersCount?: Maybe<number>
  downloadsCount?: Maybe<number>
  descriptionsCount?: Maybe<number>
  year?: Maybe<number>
}

export type UserInfoFragment = {
  __typename?: 'UserType'
  id: string
  username: string
  universityId: string
  firstName: string
  lastName: string
  email: string
  faculty: string
  lastEnrolled?: Maybe<number>
}

export type UserSubscriptionsFragment = {
  __typename?: 'UserType'
  id: string
  subscriptions: {
    __typename?: 'CourseTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'CourseTypeEdge'; node?: Maybe<{ __typename?: 'CourseType' } & CourseFragment> }>
    >
  }
}

export type AcceptAgreementsMutationVariables = Exact<{ [key: string]: never }>

export type AcceptAgreementsMutation = {
  __typename?: 'Mutation'
  acceptAgreements?: Maybe<{ __typename?: 'AcceptAgreements'; accepted?: Maybe<boolean> }>
}

export type CasTokenAuthMutationVariables = Exact<{
  ticket: Scalars['String']
  service?: Maybe<Scalars['String']>
}>

export type CasTokenAuthMutation = {
  __typename?: 'Mutation'
  casTokenAuth?: Maybe<{
    __typename?: 'CASObtainJSONWebToken'
    token: string
    payload: any
    refreshToken: string
    refreshExpiresIn: number
  }>
}

export type SendContactMutationVariables = Exact<{
  subject: Scalars['String']
  message: Scalars['String']
}>

export type SendContactMutation = {
  __typename?: 'Mutation'
  sendContact?: Maybe<{ __typename?: 'ContactSendMutation'; ok?: Maybe<boolean> }>
}

export type ChangeCourseSubscriptionMutationVariables = Exact<{
  code: Scalars['String']
  value: Scalars['Boolean']
}>

export type ChangeCourseSubscriptionMutation = {
  __typename?: 'Mutation'
  updateCourseSubscription?: Maybe<{
    __typename?: 'CourseUpdateSubscriptionMutation'
    course?: Maybe<{ __typename?: 'CourseType' } & CourseDetailFragment>
  }>
}

export type ChangeCourseDescriptionMutationVariables = Exact<{
  content: Scalars['String']
  code: Scalars['String']
}>

export type ChangeCourseDescriptionMutation = {
  __typename?: 'Mutation'
  updateCourseDescription?: Maybe<{
    __typename?: 'CourseUpdateDescriptionMutation'
    course?: Maybe<{ __typename?: 'CourseType' } & CourseDetailFragment>
  }>
}

export type RevertCourseDescriptionMutationVariables = Exact<{
  code: Scalars['String']
  revertVersionId: Scalars['ID']
}>

export type RevertCourseDescriptionMutation = {
  __typename?: 'Mutation'
  revertCourseDescription?: Maybe<{
    __typename?: 'CourseRevertDescriptionMutation'
    course?: Maybe<{ __typename?: 'CourseType' } & CourseDetailFragment>
  }>
}

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteDocumentMutation = {
  __typename?: 'Mutation'
  deleteDocument?: Maybe<{
    __typename?: 'DocumentDeleteMutation'
    document?: Maybe<{ __typename?: 'DocumentType'; id: string; name: string }>
  }>
}

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type RefreshTokenMutation = {
  __typename?: 'Mutation'
  refreshToken?: Maybe<{
    __typename?: 'Refresh'
    token: string
    payload: any
    refreshToken: string
    refreshExpiresIn: number
  }>
}

export type ReportDocumentMutationVariables = Exact<{
  code: Scalars['String']
  filename: Scalars['String']
  message: Scalars['String']
}>

export type ReportDocumentMutation = {
  __typename?: 'Mutation'
  reportDocument?: Maybe<{ __typename?: 'DocumentReportMutation'; ok?: Maybe<boolean> }>
}

export type RevokeTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type RevokeTokenMutation = {
  __typename?: 'Mutation'
  revokeToken?: Maybe<{ __typename?: 'Revoke'; revoked: number }>
}

export type UpDownloadcountMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type UpDownloadcountMutation = {
  __typename?: 'Mutation'
  upDownloadcount?: Maybe<{
    __typename?: 'DocumentUpDownloadMutation'
    document?: Maybe<{ __typename?: 'DocumentType' } & DocumentInfoFragment>
  }>
}

export type UpdateRatingMutationVariables = Exact<{
  id: Scalars['ID']
  rating: Scalars['Int']
}>

export type UpdateRatingMutation = {
  __typename?: 'Mutation'
  updateRating?: Maybe<{
    __typename?: 'DocumentEditRatingMutation'
    document?: Maybe<{ __typename?: 'DocumentType' } & DocumentInfoFragment>
  }>
}

export type UploadDocumentMutationVariables = Exact<{
  courseCode: Scalars['String']
  name: Scalars['String']
  file: Scalars['Upload']
  tags: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  anonymous?: Maybe<Scalars['Boolean']>
}>

export type UploadDocumentMutation = {
  __typename?: 'Mutation'
  uploadDocument?: Maybe<{
    __typename?: 'DocumentUploadMutation'
    document?: Maybe<{ __typename?: 'DocumentType' } & DocumentInfoFragment>
  }>
}

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String']
}>

export type VerifyTokenMutation = {
  __typename?: 'Mutation'
  verifyToken?: Maybe<{ __typename?: 'Verify'; payload: any }>
}

export type AcceptedAgreementQueryVariables = Exact<{
  url: Scalars['String']
}>

export type AcceptedAgreementQuery = { __typename?: 'Query'; acceptedAgreement?: Maybe<boolean> }

export type CourseByNameQueryVariables = Exact<{
  name?: Scalars['String']
}>

export type CourseByNameQuery = {
  __typename?: 'Query'
  allCourses?: Maybe<{
    __typename?: 'CourseTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'CourseTypeEdge'; node?: Maybe<{ __typename?: 'CourseType' } & CourseFragment> }>
    >
  }>
}

export type CourseByCodeQueryVariables = Exact<{
  code: Scalars['String']
}>

export type CourseByCodeQuery = {
  __typename?: 'Query'
  courseByCode?: Maybe<{ __typename?: 'CourseType' } & CourseDetailFragment>
}

export type CourseDocumentsQueryVariables = Exact<{
  code: Scalars['String']
}>

export type CourseDocumentsQuery = {
  __typename?: 'Query'
  courseByCode?: Maybe<{ __typename?: 'CourseType' } & CourseDocumentFragment>
}

export type CourseDescriptionVersionsQueryVariables = Exact<{
  code: Scalars['String']
}>

export type CourseDescriptionVersionsQuery = {
  __typename?: 'Query'
  courseByCode?: Maybe<{
    __typename?: 'CourseType'
    description: {
      __typename?: 'CourseDescriptionType'
      versions?: Maybe<
        Array<Maybe<{ __typename?: 'CourseDescriptionVersionType' } & CourseDescriptionVersionFragment>>
      >
    }
  }>
}

export type CourseDescriptionVersionContentQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CourseDescriptionVersionContentQuery = {
  __typename?: 'Query'
  courseDescriptionVersionById?: Maybe<
    { __typename?: 'CourseDescriptionVersionType' } & CourseDescriptionVersionContentFragment
  >
}

export type CourseDescriptionVersionChangesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CourseDescriptionVersionChangesQuery = {
  __typename?: 'Query'
  courseDescriptionVersionById?: Maybe<
    { __typename?: 'CourseDescriptionVersionType' } & CourseDescriptionVersionChangesFragment
  >
}

export type SearchCoursesQueryVariables = Exact<{
  faculty?: Maybe<Scalars['String']>
  program?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  semester?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
}>

export type SearchCoursesQuery = {
  __typename?: 'Query'
  allCourses?: Maybe<{
    __typename?: 'CourseTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'CourseTypeEdge'; node?: Maybe<{ __typename?: 'CourseType' } & CourseFragment> }>
    >
  }>
}

export type DocumentExtensionsQueryVariables = Exact<{ [key: string]: never }>

export type DocumentExtensionsQuery = { __typename?: 'Query'; documentExtensions?: Maybe<Array<Maybe<string>>> }

export type DocumentTagsQueryVariables = Exact<{ [key: string]: never }>

export type DocumentTagsQuery = {
  __typename?: 'Query'
  allDocumentTags?: Maybe<{
    __typename?: 'DocumentTagTypeConnection'
    edges: Array<
      Maybe<{
        __typename?: 'DocumentTagTypeEdge'
        node?: Maybe<{
          __typename?: 'DocumentTagType'
          id: string
          name: string
          parent?: Maybe<{ __typename?: 'DocumentTagType'; name: string }>
        }>
      }>
    >
  }>
}

export type SearchFacultyQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>
}>

export type SearchFacultyQuery = {
  __typename?: 'Query'
  allFaculties?: Maybe<{
    __typename?: 'FacultyTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'FacultyTypeEdge'; node?: Maybe<{ __typename?: 'FacultyType' } & FacultyInfoFragment> }>
    >
  }>
}

export type FlatPageQueryVariables = Exact<{
  url: Scalars['String']
}>

export type FlatPageQuery = {
  __typename?: 'Query'
  flatPage?: Maybe<{
    __typename?: 'FlatPageType'
    name?: Maybe<string>
    content?: Maybe<string>
    lastUpdatedDate?: Maybe<any>
  }>
}

export type MeSubscriptionsQueryVariables = Exact<{ [key: string]: never }>

export type MeSubscriptionsQuery = {
  __typename?: 'Query'
  me?: Maybe<{ __typename?: 'UserType' } & UserSubscriptionsFragment>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query'; me?: Maybe<{ __typename?: 'UserType' } & UserInfoFragment> }

export type SearchProgramQueryVariables = Exact<{
  faculty?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
}>

export type SearchProgramQuery = {
  __typename?: 'Query'
  allPrograms?: Maybe<{
    __typename?: 'ProgramTypeConnection'
    edges: Array<
      Maybe<{ __typename?: 'ProgramTypeEdge'; node?: Maybe<{ __typename?: 'ProgramType' } & ProgramInfoFragment> }>
    >
  }>
}

export type AllSemestersQueryVariables = Exact<{ [key: string]: never }>

export type AllSemestersQuery = { __typename?: 'Query'; allSemesters?: Maybe<Array<Maybe<string>>> }

export type WeeklyStatisticsQueryVariables = Exact<{
  number: Scalars['Int']
  faculty: Scalars['String']
}>

export type WeeklyStatisticsQuery = {
  __typename?: 'Query'
  weeklyStatistics?: Maybe<Array<Maybe<{ __typename?: 'StatisticsType' } & StatisticsWeekFragment>>>
  monthlyStatistics?: Maybe<Array<Maybe<{ __typename?: 'StatisticsType' } & StatisticsWeekFragment>>>
  totalStatistics?: Maybe<{ __typename?: 'TotalStatisticsType' } & StatisticsTotalFragment>
}

export type ComparisonStatisticsQueryVariables = Exact<{
  year: Scalars['Int']
}>

export type ComparisonStatisticsQuery = {
  __typename?: 'Query'
  comparisonStatistics?: Maybe<
    Array<Maybe<{ __typename?: 'ComparisonStatisticsType' } & StatisticsComparisonFragment>>
  >
}

export type AllYearsQueryVariables = Exact<{
  program?: Maybe<Scalars['String']>
}>

export type AllYearsQuery = { __typename?: 'Query'; allYears?: Maybe<Array<Maybe<string>>> }

export const CourseDescriptionVersionChangesFragmentDoc = gql`
  fragment courseDescriptionVersionChanges on CourseDescriptionVersionType {
    id
    changes
  }
`
export const CourseDescriptionVersionContentFragmentDoc = gql`
  fragment courseDescriptionVersionContent on CourseDescriptionVersionType {
    id
    content
  }
`
export const CourseDescriptionVersionFragmentDoc = gql`
  fragment courseDescriptionVersion on CourseDescriptionVersionType {
    id
    number
    changesAddedAmount
    changesDeletedAmount
    revision {
      dateCreated
      user {
        firstName
        lastName
      }
    }
    revertedVersion {
      number
    }
  }
`
export const CourseDetailFragmentDoc = gql`
  fragment courseDetail on CourseType {
    id
    code
    name
    subscribed
    instructor {
      name
    }
    description {
      content
      lastVersionDate
      lastVersionUser {
        firstName
        lastName
        username
      }
      versionsAmount
    }
  }
`
export const DocumentInfoFragmentDoc = gql`
  fragment documentInfo on DocumentType {
    id
    author {
      firstName
      lastName
      username
    }
    name
    pageCount
    downloadCount
    uploadDate
    lastUpdated
    tags {
      edges {
        node {
          name
        }
      }
    }
    file
    anonymous
    averageRatings
    myRating
    ratingsCount
  }
`
export const CourseDocumentFragmentDoc = gql`
  fragment courseDocument on CourseType {
    id
    documents {
      edges {
        node {
          ...documentInfo
        }
      }
    }
  }
  ${DocumentInfoFragmentDoc}
`
export const FacultyInfoFragmentDoc = gql`
  fragment facultyInfo on FacultyType {
    code
    name
  }
`
export const ProgramInfoFragmentDoc = gql`
  fragment programInfo on ProgramType {
    code
    name
  }
`
export const StatisticsComparisonFragmentDoc = gql`
  fragment statisticsComparison on ComparisonStatisticsType {
    studentSociety
    documentsUploaded
  }
`
export const StatisticsTotalFragmentDoc = gql`
  fragment statisticsTotal on TotalStatisticsType {
    totalUsersCount
    totalDocumentsCount
  }
`
export const StatisticsWeekFragmentDoc = gql`
  fragment statisticsWeek on StatisticsType {
    periodNumber
    newDocumentsCount
    newUsersCount
    downloadsCount
    descriptionsCount
    year
  }
`
export const UserInfoFragmentDoc = gql`
  fragment userInfo on UserType {
    id
    username
    universityId
    firstName
    lastName
    email
    faculty
    lastEnrolled
  }
`
export const CourseFragmentDoc = gql`
  fragment course on CourseType {
    id
    code
    name
    subscribed
    instructor {
      name
    }
  }
`
export const UserSubscriptionsFragmentDoc = gql`
  fragment userSubscriptions on UserType {
    id
    subscriptions {
      edges {
        node {
          ...course
        }
      }
    }
  }
  ${CourseFragmentDoc}
`
export const AcceptAgreementsDocument = gql`
  mutation acceptAgreements {
    acceptAgreements {
      accepted
    }
  }
`

/**
 * __useAcceptAgreementsMutation__
 *
 * To run a mutation, you first call `useAcceptAgreementsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAgreementsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAcceptAgreementsMutation();
 */
export function useAcceptAgreementsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>
    | ReactiveFunction<
      VueApolloComposable.UseMutationOptions<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>
    > = {}
) {
  return VueApolloComposable.useMutation<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>(
    AcceptAgreementsDocument,
    options
  )
}
export type AcceptAgreementsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  AcceptAgreementsMutation,
  AcceptAgreementsMutationVariables
>
export const CasTokenAuthDocument = gql`
  mutation casTokenAuth($ticket: String!, $service: String) {
    casTokenAuth(ticket: $ticket, service: $service) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`

/**
 * __useCasTokenAuthMutation__
 *
 * To run a mutation, you first call `useCasTokenAuthMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCasTokenAuthMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCasTokenAuthMutation({
 *   variables: {
 *     ticket: // value for 'ticket'
 *     service: // value for 'service'
 *   },
 * });
 */
export function useCasTokenAuthMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CasTokenAuthMutation, CasTokenAuthMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CasTokenAuthMutation, CasTokenAuthMutationVariables>>
) {
  return VueApolloComposable.useMutation<CasTokenAuthMutation, CasTokenAuthMutationVariables>(
    CasTokenAuthDocument,
    options
  )
}
export type CasTokenAuthMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CasTokenAuthMutation,
  CasTokenAuthMutationVariables
>
export const SendContactDocument = gql`
  mutation sendContact($subject: String!, $message: String!) {
    sendContact(subject: $subject, message: $message) {
      ok
    }
  }
`

/**
 * __useSendContactMutation__
 *
 * To run a mutation, you first call `useSendContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendContactMutation({
 *   variables: {
 *     subject: // value for 'subject'
 *     message: // value for 'message'
 *   },
 * });
 */
export function useSendContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendContactMutation, SendContactMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendContactMutation, SendContactMutationVariables>>
) {
  return VueApolloComposable.useMutation<SendContactMutation, SendContactMutationVariables>(
    SendContactDocument,
    options
  )
}
export type SendContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendContactMutation,
  SendContactMutationVariables
>
export const ChangeCourseSubscriptionDocument = gql`
  mutation changeCourseSubscription($code: String!, $value: Boolean!) {
    updateCourseSubscription(value: $value, code: $code) {
      course {
        ...courseDetail
      }
    }
  }
  ${CourseDetailFragmentDoc}
`

/**
 * __useChangeCourseSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeCourseSubscriptionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeCourseSubscriptionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeCourseSubscriptionMutation({
 *   variables: {
 *     code: // value for 'code'
 *     value: // value for 'value'
 *   },
 * });
 */
export function useChangeCourseSubscriptionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
      ChangeCourseSubscriptionMutation,
      ChangeCourseSubscriptionMutationVariables
    >
    | ReactiveFunction<
      VueApolloComposable.UseMutationOptions<
        ChangeCourseSubscriptionMutation,
        ChangeCourseSubscriptionMutationVariables
      >
    >
) {
  return VueApolloComposable.useMutation<ChangeCourseSubscriptionMutation, ChangeCourseSubscriptionMutationVariables>(
    ChangeCourseSubscriptionDocument,
    options
  )
}
export type ChangeCourseSubscriptionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ChangeCourseSubscriptionMutation,
  ChangeCourseSubscriptionMutationVariables
>
export const ChangeCourseDescriptionDocument = gql`
  mutation changeCourseDescription($content: String!, $code: String!) {
    updateCourseDescription(content: $content, code: $code) {
      course {
        ...courseDetail
      }
    }
  }
  ${CourseDetailFragmentDoc}
`

/**
 * __useChangeCourseDescriptionMutation__
 *
 * To run a mutation, you first call `useChangeCourseDescriptionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeCourseDescriptionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeCourseDescriptionMutation({
 *   variables: {
 *     content: // value for 'content'
 *     code: // value for 'code'
 *   },
 * });
 */
export function useChangeCourseDescriptionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ChangeCourseDescriptionMutation, ChangeCourseDescriptionMutationVariables>
    | ReactiveFunction<
      VueApolloComposable.UseMutationOptions<
        ChangeCourseDescriptionMutation,
        ChangeCourseDescriptionMutationVariables
      >
    >
) {
  return VueApolloComposable.useMutation<ChangeCourseDescriptionMutation, ChangeCourseDescriptionMutationVariables>(
    ChangeCourseDescriptionDocument,
    options
  )
}
export type ChangeCourseDescriptionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ChangeCourseDescriptionMutation,
  ChangeCourseDescriptionMutationVariables
>
export const RevertCourseDescriptionDocument = gql`
  mutation revertCourseDescription($code: String!, $revertVersionId: ID!) {
    revertCourseDescription(code: $code, revertVersionId: $revertVersionId) {
      course {
        ...courseDetail
      }
    }
  }
  ${CourseDetailFragmentDoc}
`

/**
 * __useRevertCourseDescriptionMutation__
 *
 * To run a mutation, you first call `useRevertCourseDescriptionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRevertCourseDescriptionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRevertCourseDescriptionMutation({
 *   variables: {
 *     code: // value for 'code'
 *     revertVersionId: // value for 'revertVersionId'
 *   },
 * });
 */
export function useRevertCourseDescriptionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<RevertCourseDescriptionMutation, RevertCourseDescriptionMutationVariables>
    | ReactiveFunction<
      VueApolloComposable.UseMutationOptions<
        RevertCourseDescriptionMutation,
        RevertCourseDescriptionMutationVariables
      >
    >
) {
  return VueApolloComposable.useMutation<RevertCourseDescriptionMutation, RevertCourseDescriptionMutationVariables>(
    RevertCourseDescriptionDocument,
    options
  )
}
export type RevertCourseDescriptionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  RevertCourseDescriptionMutation,
  RevertCourseDescriptionMutationVariables
>
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      document {
        id
        name
      }
    }
  }
`

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDocumentMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>>
) {
  return VueApolloComposable.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(
    DeleteDocumentDocument,
    options
  )
}
export type DeleteDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const RefreshTokenDocument = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRefreshTokenMutation({
 *   variables: {
 *     refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>>
) {
  return VueApolloComposable.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options
  )
}
export type RefreshTokenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>
export const ReportDocumentDocument = gql`
  mutation reportDocument($code: String!, $filename: String!, $message: String!) {
    reportDocument(code: $code, filename: $filename, message: $message) {
      ok
    }
  }
`

/**
 * __useReportDocumentMutation__
 *
 * To run a mutation, you first call `useReportDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReportDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReportDocumentMutation({
 *   variables: {
 *     code: // value for 'code'
 *     filename: // value for 'filename'
 *     message: // value for 'message'
 *   },
 * });
 */
export function useReportDocumentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ReportDocumentMutation, ReportDocumentMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReportDocumentMutation, ReportDocumentMutationVariables>>
) {
  return VueApolloComposable.useMutation<ReportDocumentMutation, ReportDocumentMutationVariables>(
    ReportDocumentDocument,
    options
  )
}
export type ReportDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ReportDocumentMutation,
  ReportDocumentMutationVariables
>
export const RevokeTokenDocument = gql`
  mutation revokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`

/**
 * __useRevokeTokenMutation__
 *
 * To run a mutation, you first call `useRevokeTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRevokeTokenMutation({
 *   variables: {
 *     refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRevokeTokenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<RevokeTokenMutation, RevokeTokenMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<RevokeTokenMutation, RevokeTokenMutationVariables>>
) {
  return VueApolloComposable.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(
    RevokeTokenDocument,
    options
  )
}
export type RevokeTokenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  RevokeTokenMutation,
  RevokeTokenMutationVariables
>
export const UpDownloadcountDocument = gql`
  mutation upDownloadcount($id: ID!) {
    upDownloadcount(doc: $id) {
      document {
        ...documentInfo
      }
    }
  }
  ${DocumentInfoFragmentDoc}
`

/**
 * __useUpDownloadcountMutation__
 *
 * To run a mutation, you first call `useUpDownloadcountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpDownloadcountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpDownloadcountMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useUpDownloadcountMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpDownloadcountMutation, UpDownloadcountMutationVariables>
    | ReactiveFunction<
      VueApolloComposable.UseMutationOptions<UpDownloadcountMutation, UpDownloadcountMutationVariables>
    >
) {
  return VueApolloComposable.useMutation<UpDownloadcountMutation, UpDownloadcountMutationVariables>(
    UpDownloadcountDocument,
    options
  )
}
export type UpDownloadcountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpDownloadcountMutation,
  UpDownloadcountMutationVariables
>
export const UpdateRatingDocument = gql`
  mutation updateRating($id: ID!, $rating: Int!) {
    updateRating(id: $id, rating: $rating) {
      document {
        ...documentInfo
      }
    }
  }
  ${DocumentInfoFragmentDoc}
`

/**
 * __useUpdateRatingMutation__
 *
 * To run a mutation, you first call `useUpdateRatingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRatingMutation({
 *   variables: {
 *     id: // value for 'id'
 *     rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateRatingMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateRatingMutation, UpdateRatingMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateRatingMutation, UpdateRatingMutationVariables>>
) {
  return VueApolloComposable.useMutation<UpdateRatingMutation, UpdateRatingMutationVariables>(
    UpdateRatingDocument,
    options
  )
}
export type UpdateRatingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateRatingMutation,
  UpdateRatingMutationVariables
>
export const UploadDocumentDocument = gql`
  mutation uploadDocument(
    $courseCode: String!
    $name: String!
    $file: Upload!
    $tags: [String]!
    $anonymous: Boolean
  ) {
    uploadDocument(courseCode: $courseCode, name: $name, file: $file, tags: $tags, anonymous: $anonymous) {
      document {
        ...documentInfo
      }
    }
  }
  ${DocumentInfoFragmentDoc}
`

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadDocumentMutation({
 *   variables: {
 *     courseCode: // value for 'courseCode'
 *     name: // value for 'name'
 *     file: // value for 'file'
 *     tags: // value for 'tags'
 *     anonymous: // value for 'anonymous'
 *   },
 * });
 */
export function useUploadDocumentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>>
) {
  return VueApolloComposable.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(
    UploadDocumentDocument,
    options
  )
}
export type UploadDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UploadDocumentMutation,
  UploadDocumentMutationVariables
>
export const VerifyTokenDocument = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyTokenMutation({
 *   variables: {
 *     token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(
  options:
    | VueApolloComposable.UseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>>
) {
  return VueApolloComposable.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(
    VerifyTokenDocument,
    options
  )
}
export type VerifyTokenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  VerifyTokenMutation,
  VerifyTokenMutationVariables
>
export const AcceptedAgreementDocument = gql`
  query acceptedAgreement($url: String!) {
    acceptedAgreement(url: $url)
  }
`

/**
 * __useAcceptedAgreementQuery__
 *
 * To run a query within a Vue component, call `useAcceptedAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptedAgreementQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAcceptedAgreementQuery({
 *   url: // value for 'url'
 * });
 */
export function useAcceptedAgreementQuery(
  variables:
    | AcceptedAgreementQueryVariables
    | VueCompositionApi.Ref<AcceptedAgreementQueryVariables>
    | ReactiveFunction<AcceptedAgreementQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<AcceptedAgreementQuery, AcceptedAgreementQueryVariables>
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<AcceptedAgreementQuery, AcceptedAgreementQueryVariables>
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<AcceptedAgreementQuery, AcceptedAgreementQueryVariables>
    > = {}
) {
  return VueApolloComposable.useQuery<AcceptedAgreementQuery, AcceptedAgreementQueryVariables>(
    AcceptedAgreementDocument,
    variables,
    options
  )
}
export type AcceptedAgreementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  AcceptedAgreementQuery,
  AcceptedAgreementQueryVariables
>
export const CourseByNameDocument = gql`
  query courseByName($name: String! = "") {
    allCourses(search: $name) {
      edges {
        node {
          ...course
        }
      }
    }
  }
  ${CourseFragmentDoc}
`

/**
 * __useCourseByNameQuery__
 *
 * To run a query within a Vue component, call `useCourseByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseByNameQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseByNameQuery({
 *   name: // value for 'name'
 * });
 */
export function useCourseByNameQuery(
  variables:
    | CourseByNameQueryVariables
    | VueCompositionApi.Ref<CourseByNameQueryVariables>
    | ReactiveFunction<CourseByNameQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CourseByNameQuery, CourseByNameQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CourseByNameQuery, CourseByNameQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CourseByNameQuery, CourseByNameQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<CourseByNameQuery, CourseByNameQueryVariables>(
    CourseByNameDocument,
    variables,
    options
  )
}
export type CourseByNameQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseByNameQuery,
  CourseByNameQueryVariables
>
export const CourseByCodeDocument = gql`
  query courseByCode($code: String!) {
    courseByCode(code: $code) {
      ...courseDetail
    }
  }
  ${CourseDetailFragmentDoc}
`

/**
 * __useCourseByCodeQuery__
 *
 * To run a query within a Vue component, call `useCourseByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseByCodeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseByCodeQuery({
 *   code: // value for 'code'
 * });
 */
export function useCourseByCodeQuery(
  variables:
    | CourseByCodeQueryVariables
    | VueCompositionApi.Ref<CourseByCodeQueryVariables>
    | ReactiveFunction<CourseByCodeQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CourseByCodeQuery, CourseByCodeQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CourseByCodeQuery, CourseByCodeQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CourseByCodeQuery, CourseByCodeQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<CourseByCodeQuery, CourseByCodeQueryVariables>(
    CourseByCodeDocument,
    variables,
    options
  )
}
export type CourseByCodeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseByCodeQuery,
  CourseByCodeQueryVariables
>
export const CourseDocumentsDocument = gql`
  query courseDocuments($code: String!) {
    courseByCode(code: $code) {
      ...courseDocument
    }
  }
  ${CourseDocumentFragmentDoc}
`

/**
 * __useCourseDocumentsQuery__
 *
 * To run a query within a Vue component, call `useCourseDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDocumentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseDocumentsQuery({
 *   code: // value for 'code'
 * });
 */
export function useCourseDocumentsQuery(
  variables:
    | CourseDocumentsQueryVariables
    | VueCompositionApi.Ref<CourseDocumentsQueryVariables>
    | ReactiveFunction<CourseDocumentsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CourseDocumentsQuery, CourseDocumentsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CourseDocumentsQuery, CourseDocumentsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CourseDocumentsQuery, CourseDocumentsQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<CourseDocumentsQuery, CourseDocumentsQueryVariables>(
    CourseDocumentsDocument,
    variables,
    options
  )
}
export type CourseDocumentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseDocumentsQuery,
  CourseDocumentsQueryVariables
>
export const CourseDescriptionVersionsDocument = gql`
  query courseDescriptionVersions($code: String!) {
    courseByCode(code: $code) {
      description {
        versions {
          ...courseDescriptionVersion
        }
      }
    }
  }
  ${CourseDescriptionVersionFragmentDoc}
`

/**
 * __useCourseDescriptionVersionsQuery__
 *
 * To run a query within a Vue component, call `useCourseDescriptionVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDescriptionVersionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseDescriptionVersionsQuery({
 *   code: // value for 'code'
 * });
 */
export function useCourseDescriptionVersionsQuery(
  variables:
    | CourseDescriptionVersionsQueryVariables
    | VueCompositionApi.Ref<CourseDescriptionVersionsQueryVariables>
    | ReactiveFunction<CourseDescriptionVersionsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CourseDescriptionVersionsQuery, CourseDescriptionVersionsQueryVariables>
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<CourseDescriptionVersionsQuery, CourseDescriptionVersionsQueryVariables>
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<CourseDescriptionVersionsQuery, CourseDescriptionVersionsQueryVariables>
    > = {}
) {
  return VueApolloComposable.useQuery<CourseDescriptionVersionsQuery, CourseDescriptionVersionsQueryVariables>(
    CourseDescriptionVersionsDocument,
    variables,
    options
  )
}
export type CourseDescriptionVersionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseDescriptionVersionsQuery,
  CourseDescriptionVersionsQueryVariables
>
export const CourseDescriptionVersionContentDocument = gql`
  query courseDescriptionVersionContent($id: ID!) {
    courseDescriptionVersionById(id: $id) {
      ...courseDescriptionVersionContent
    }
  }
  ${CourseDescriptionVersionContentFragmentDoc}
`

/**
 * __useCourseDescriptionVersionContentQuery__
 *
 * To run a query within a Vue component, call `useCourseDescriptionVersionContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDescriptionVersionContentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseDescriptionVersionContentQuery({
 *   id: // value for 'id'
 * });
 */
export function useCourseDescriptionVersionContentQuery(
  variables:
    | CourseDescriptionVersionContentQueryVariables
    | VueCompositionApi.Ref<CourseDescriptionVersionContentQueryVariables>
    | ReactiveFunction<CourseDescriptionVersionContentQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
      CourseDescriptionVersionContentQuery,
      CourseDescriptionVersionContentQueryVariables
    >
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<
        CourseDescriptionVersionContentQuery,
        CourseDescriptionVersionContentQueryVariables
      >
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<
        CourseDescriptionVersionContentQuery,
        CourseDescriptionVersionContentQueryVariables
      >
    > = {}
) {
  return VueApolloComposable.useQuery<
    CourseDescriptionVersionContentQuery,
    CourseDescriptionVersionContentQueryVariables
  >(CourseDescriptionVersionContentDocument, variables, options)
}
export type CourseDescriptionVersionContentQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseDescriptionVersionContentQuery,
  CourseDescriptionVersionContentQueryVariables
>
export const CourseDescriptionVersionChangesDocument = gql`
  query courseDescriptionVersionChanges($id: ID!) {
    courseDescriptionVersionById(id: $id) {
      ...courseDescriptionVersionChanges
    }
  }
  ${CourseDescriptionVersionChangesFragmentDoc}
`

/**
 * __useCourseDescriptionVersionChangesQuery__
 *
 * To run a query within a Vue component, call `useCourseDescriptionVersionChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDescriptionVersionChangesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCourseDescriptionVersionChangesQuery({
 *   id: // value for 'id'
 * });
 */
export function useCourseDescriptionVersionChangesQuery(
  variables:
    | CourseDescriptionVersionChangesQueryVariables
    | VueCompositionApi.Ref<CourseDescriptionVersionChangesQueryVariables>
    | ReactiveFunction<CourseDescriptionVersionChangesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
      CourseDescriptionVersionChangesQuery,
      CourseDescriptionVersionChangesQueryVariables
    >
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<
        CourseDescriptionVersionChangesQuery,
        CourseDescriptionVersionChangesQueryVariables
      >
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<
        CourseDescriptionVersionChangesQuery,
        CourseDescriptionVersionChangesQueryVariables
      >
    > = {}
) {
  return VueApolloComposable.useQuery<
    CourseDescriptionVersionChangesQuery,
    CourseDescriptionVersionChangesQueryVariables
  >(CourseDescriptionVersionChangesDocument, variables, options)
}
export type CourseDescriptionVersionChangesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CourseDescriptionVersionChangesQuery,
  CourseDescriptionVersionChangesQueryVariables
>
export const SearchCoursesDocument = gql`
  query searchCourses($faculty: String, $program: String, $year: String, $semester: String, $search: String) {
    allCourses(
      faculty: $faculty
      program: $program
      year: $year
      semester: $semester
      search: $search
      orderBy: "name"
    ) {
      edges {
        node {
          ...course
        }
      }
    }
  }
  ${CourseFragmentDoc}
`

/**
 * __useSearchCoursesQuery__
 *
 * To run a query within a Vue component, call `useSearchCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoursesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchCoursesQuery({
 *   faculty: // value for 'faculty'
 *   program: // value for 'program'
 *   year: // value for 'year'
 *   semester: // value for 'semester'
 *   search: // value for 'search'
 * });
 */
export function useSearchCoursesQuery(
  variables:
    | SearchCoursesQueryVariables
    | VueCompositionApi.Ref<SearchCoursesQueryVariables>
    | ReactiveFunction<SearchCoursesQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<SearchCoursesQuery, SearchCoursesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchCoursesQuery, SearchCoursesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchCoursesQuery, SearchCoursesQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<SearchCoursesQuery, SearchCoursesQueryVariables>(
    SearchCoursesDocument,
    variables,
    options
  )
}
export type SearchCoursesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  SearchCoursesQuery,
  SearchCoursesQueryVariables
>
export const DocumentExtensionsDocument = gql`
  query DocumentExtensions {
    documentExtensions
  }
`

/**
 * __useDocumentExtensionsQuery__
 *
 * To run a query within a Vue component, call `useDocumentExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentExtensionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDocumentExtensionsQuery();
 */
export function useDocumentExtensionsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<DocumentExtensionsQuery, DocumentExtensionsQueryVariables>
    | VueCompositionApi.Ref<
      VueApolloComposable.UseQueryOptions<DocumentExtensionsQuery, DocumentExtensionsQueryVariables>
    >
    | ReactiveFunction<
      VueApolloComposable.UseQueryOptions<DocumentExtensionsQuery, DocumentExtensionsQueryVariables>
    > = {}
) {
  return VueApolloComposable.useQuery<DocumentExtensionsQuery, DocumentExtensionsQueryVariables>(
    DocumentExtensionsDocument,
    {},
    options
  )
}
export type DocumentExtensionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  DocumentExtensionsQuery,
  DocumentExtensionsQueryVariables
>
export const DocumentTagsDocument = gql`
  query documentTags {
    allDocumentTags {
      edges {
        node {
          id
          name
          parent {
            name
          }
        }
      }
    }
  }
`

/**
 * __useDocumentTagsQuery__
 *
 * To run a query within a Vue component, call `useDocumentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTagsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDocumentTagsQuery();
 */
export function useDocumentTagsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<DocumentTagsQuery, DocumentTagsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DocumentTagsQuery, DocumentTagsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<DocumentTagsQuery, DocumentTagsQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<DocumentTagsQuery, DocumentTagsQueryVariables>(DocumentTagsDocument, {}, options)
}
export type DocumentTagsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  DocumentTagsQuery,
  DocumentTagsQueryVariables
>
export const SearchFacultyDocument = gql`
  query searchFaculty($search: String) {
    allFaculties(search: $search, orderBy: "name") {
      edges {
        node {
          ...facultyInfo
        }
      }
    }
  }
  ${FacultyInfoFragmentDoc}
`

/**
 * __useSearchFacultyQuery__
 *
 * To run a query within a Vue component, call `useSearchFacultyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFacultyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchFacultyQuery({
 *   search: // value for 'search'
 * });
 */
export function useSearchFacultyQuery(
  variables:
    | SearchFacultyQueryVariables
    | VueCompositionApi.Ref<SearchFacultyQueryVariables>
    | ReactiveFunction<SearchFacultyQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<SearchFacultyQuery, SearchFacultyQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchFacultyQuery, SearchFacultyQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchFacultyQuery, SearchFacultyQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<SearchFacultyQuery, SearchFacultyQueryVariables>(
    SearchFacultyDocument,
    variables,
    options
  )
}
export type SearchFacultyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  SearchFacultyQuery,
  SearchFacultyQueryVariables
>
export const FlatPageDocument = gql`
  query flatPage($url: String!) {
    flatPage(url: $url) {
      name
      content
      lastUpdatedDate
    }
  }
`

/**
 * __useFlatPageQuery__
 *
 * To run a query within a Vue component, call `useFlatPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlatPageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFlatPageQuery({
 *   url: // value for 'url'
 * });
 */
export function useFlatPageQuery(
  variables:
    | FlatPageQueryVariables
    | VueCompositionApi.Ref<FlatPageQueryVariables>
    | ReactiveFunction<FlatPageQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<FlatPageQuery, FlatPageQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FlatPageQuery, FlatPageQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<FlatPageQuery, FlatPageQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<FlatPageQuery, FlatPageQueryVariables>(FlatPageDocument, variables, options)
}
export type FlatPageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  FlatPageQuery,
  FlatPageQueryVariables
>
export const MeSubscriptionsDocument = gql`
  query MeSubscriptions {
    me {
      ...userSubscriptions
    }
  }
  ${UserSubscriptionsFragmentDoc}
`

/**
 * __useMeSubscriptionsQuery__
 *
 * To run a query within a Vue component, call `useMeSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeSubscriptionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeSubscriptionsQuery();
 */
export function useMeSubscriptionsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<MeSubscriptionsQuery, MeSubscriptionsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeSubscriptionsQuery, MeSubscriptionsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeSubscriptionsQuery, MeSubscriptionsQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<MeSubscriptionsQuery, MeSubscriptionsQueryVariables>(
    MeSubscriptionsDocument,
    {},
    options
  )
}
export type MeSubscriptionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  MeSubscriptionsQuery,
  MeSubscriptionsQueryVariables
>
export const MeDocument = gql`
  query Me {
    me {
      ...userInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(
  options:
    | VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options)
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>
export const SearchProgramDocument = gql`
  query searchProgram($faculty: String, $search: String) {
    allPrograms(faculty: $faculty, search: $search, orderBy: "name") {
      edges {
        node {
          ...programInfo
        }
      }
    }
  }
  ${ProgramInfoFragmentDoc}
`

/**
 * __useSearchProgramQuery__
 *
 * To run a query within a Vue component, call `useSearchProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProgramQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchProgramQuery({
 *   faculty: // value for 'faculty'
 *   search: // value for 'search'
 * });
 */
export function useSearchProgramQuery(
  variables:
    | SearchProgramQueryVariables
    | VueCompositionApi.Ref<SearchProgramQueryVariables>
    | ReactiveFunction<SearchProgramQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<SearchProgramQuery, SearchProgramQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchProgramQuery, SearchProgramQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchProgramQuery, SearchProgramQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<SearchProgramQuery, SearchProgramQueryVariables>(
    SearchProgramDocument,
    variables,
    options
  )
}
export type SearchProgramQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  SearchProgramQuery,
  SearchProgramQueryVariables
>
export const AllSemestersDocument = gql`
  query allSemesters {
    allSemesters
  }
`

/**
 * __useAllSemestersQuery__
 *
 * To run a query within a Vue component, call `useAllSemestersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSemestersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllSemestersQuery();
 */
export function useAllSemestersQuery(
  options:
    | VueApolloComposable.UseQueryOptions<AllSemestersQuery, AllSemestersQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllSemestersQuery, AllSemestersQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllSemestersQuery, AllSemestersQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<AllSemestersQuery, AllSemestersQueryVariables>(AllSemestersDocument, {}, options)
}
export type AllSemestersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  AllSemestersQuery,
  AllSemestersQueryVariables
>
export const WeeklyStatisticsDocument = gql`
  query weeklyStatistics($number: Int!, $faculty: String!) {
    weeklyStatistics(number: $number, faculty: $faculty) {
      ...statisticsWeek
    }
    monthlyStatistics(number: $number, faculty: $faculty) {
      ...statisticsWeek
    }
    totalStatistics(faculty: $faculty) {
      ...statisticsTotal
    }
  }
  ${StatisticsWeekFragmentDoc}
  ${StatisticsTotalFragmentDoc}
`

/**
 * __useWeeklyStatisticsQuery__
 *
 * To run a query within a Vue component, call `useWeeklyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyStatisticsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWeeklyStatisticsQuery({
 *   number: // value for 'number'
 *   faculty: // value for 'faculty'
 * });
 */
export function useWeeklyStatisticsQuery(
  variables:
    | WeeklyStatisticsQueryVariables
    | VueCompositionApi.Ref<WeeklyStatisticsQueryVariables>
    | ReactiveFunction<WeeklyStatisticsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<WeeklyStatisticsQuery, WeeklyStatisticsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WeeklyStatisticsQuery, WeeklyStatisticsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<WeeklyStatisticsQuery, WeeklyStatisticsQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<WeeklyStatisticsQuery, WeeklyStatisticsQueryVariables>(
    WeeklyStatisticsDocument,
    variables,
    options
  )
}
export type WeeklyStatisticsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  WeeklyStatisticsQuery,
  WeeklyStatisticsQueryVariables
>
export const ComparisonStatisticsDocument = gql`
  query comparisonStatistics($year: Int!) {
    comparisonStatistics(year: $year) {
      ...statisticsComparison
    }
  }
  ${StatisticsComparisonFragmentDoc}
`

/**
 * __useComparisonStatisticsQuery__
 *
 * To run a query within a Vue component, call `useComparisonStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComparisonStatisticsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useComparisonStatisticsQuery({
 *   year: // value for 'year'
 * });
 */
export function useComparisonStatisticsQuery(
  variables:
    | ComparisonStatisticsQueryVariables
    | VueCompositionApi.Ref<ComparisonStatisticsQueryVariables>
    | ReactiveFunction<ComparisonStatisticsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ComparisonStatisticsQuery, ComparisonStatisticsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ComparisonStatisticsQuery, ComparisonStatisticsQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ComparisonStatisticsQuery, ComparisonStatisticsQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<ComparisonStatisticsQuery, ComparisonStatisticsQueryVariables>(
    ComparisonStatisticsDocument,
    variables,
    options
  )
}
export type ComparisonStatisticsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ComparisonStatisticsQuery,
  ComparisonStatisticsQueryVariables
>
export const AllYearsDocument = gql`
  query allYears($program: String) {
    allYears(program: $program)
  }
`

/**
 * __useAllYearsQuery__
 *
 * To run a query within a Vue component, call `useAllYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllYearsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllYearsQuery({
 *   program: // value for 'program'
 * });
 */
export function useAllYearsQuery(
  variables:
    | AllYearsQueryVariables
    | VueCompositionApi.Ref<AllYearsQueryVariables>
    | ReactiveFunction<AllYearsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<AllYearsQuery, AllYearsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AllYearsQuery, AllYearsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<AllYearsQuery, AllYearsQueryVariables>> = {}
) {
  return VueApolloComposable.useQuery<AllYearsQuery, AllYearsQueryVariables>(AllYearsDocument, variables, options)
}
export type AllYearsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  AllYearsQuery,
  AllYearsQueryVariables
>
