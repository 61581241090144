export const AnimatedCheck = () => import('../../components/AnimatedCheck.vue' /* webpackChunkName: "components/animated-check" */).then(c => wrapFunctional(c.default || c))
export const ContentPlaceholder = () => import('../../components/ContentPlaceholder.vue' /* webpackChunkName: "components/content-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PageError = () => import('../../components/PageError.vue' /* webpackChunkName: "components/page-error" */).then(c => wrapFunctional(c.default || c))
export const PageLoading = () => import('../../components/PageLoading.vue' /* webpackChunkName: "components/page-loading" */).then(c => wrapFunctional(c.default || c))
export const AgreementDialog = () => import('../../components/agreement/AgreementDialog.vue' /* webpackChunkName: "components/agreement-dialog" */).then(c => wrapFunctional(c.default || c))
export const CookieConsent = () => import('../../components/agreement/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/contact/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ReportForm = () => import('../../components/contact/ReportForm.vue' /* webpackChunkName: "components/report-form" */).then(c => wrapFunctional(c.default || c))
export const CourseCard = () => import('../../components/course/CourseCard.vue' /* webpackChunkName: "components/course-card" */).then(c => wrapFunctional(c.default || c))
export const CourseCardList = () => import('../../components/course/CourseCardList.vue' /* webpackChunkName: "components/course-card-list" */).then(c => wrapFunctional(c.default || c))
export const CourseDescription = () => import('../../components/course/CourseDescription.vue' /* webpackChunkName: "components/course-description" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionEditor = () => import('../../components/course/CourseDescriptionEditor.vue' /* webpackChunkName: "components/course-description-editor" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionVersionCard = () => import('../../components/course/CourseDescriptionVersionCard.vue' /* webpackChunkName: "components/course-description-version-card" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionVersionDiff = () => import('../../components/course/CourseDescriptionVersionDiff.vue' /* webpackChunkName: "components/course-description-version-diff" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionVersions = () => import('../../components/course/CourseDescriptionVersions.vue' /* webpackChunkName: "components/course-description-versions" */).then(c => wrapFunctional(c.default || c))
export const CourseFileCard = () => import('../../components/course/CourseFileCard.vue' /* webpackChunkName: "components/course-file-card" */).then(c => wrapFunctional(c.default || c))
export const CourseFileList = () => import('../../components/course/CourseFileList.vue' /* webpackChunkName: "components/course-file-list" */).then(c => wrapFunctional(c.default || c))
export const CourseFiles = () => import('../../components/course/CourseFiles.vue' /* webpackChunkName: "components/course-files" */).then(c => wrapFunctional(c.default || c))
export const CourseFilesSortBtn = () => import('../../components/course/CourseFilesSortBtn.vue' /* webpackChunkName: "components/course-files-sort-btn" */).then(c => wrapFunctional(c.default || c))
export const SnackBar = () => import('../../components/document/SnackBar.vue' /* webpackChunkName: "components/snack-bar" */).then(c => wrapFunctional(c.default || c))
export const FlatPage = () => import('../../components/flatpage/FlatPage.vue' /* webpackChunkName: "components/flat-page" */).then(c => wrapFunctional(c.default || c))
export const LoginButton = () => import('../../components/landing/LoginButton.vue' /* webpackChunkName: "components/login-button" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelector = () => import('../../components/language/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c))
export const FeedbackBanner = () => import('../../components/layout/FeedbackBanner.vue' /* webpackChunkName: "components/feedback-banner" */).then(c => wrapFunctional(c.default || c))
export const FootBar = () => import('../../components/layout/FootBar.vue' /* webpackChunkName: "components/foot-bar" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/layout/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NotificationCard = () => import('../../components/layout/NotificationCard.vue' /* webpackChunkName: "components/notification-card" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/layout/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const SplashScreen = () => import('../../components/layout/SplashScreen.vue' /* webpackChunkName: "components/splash-screen" */).then(c => wrapFunctional(c.default || c))
export const TheLogo = () => import('../../components/layout/TheLogo.vue' /* webpackChunkName: "components/the-logo" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/layout/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const StatisticsCard = () => import('../../components/statistics/StatisticsCard.vue' /* webpackChunkName: "components/statistics-card" */).then(c => wrapFunctional(c.default || c))
export const SuspenseMutation = () => import('../../components/suspense/SuspenseMutation.vue' /* webpackChunkName: "components/suspense-mutation" */).then(c => wrapFunctional(c.default || c))
export const SuspenseQuery = () => import('../../components/suspense/SuspenseQuery.vue' /* webpackChunkName: "components/suspense-query" */).then(c => wrapFunctional(c.default || c))
export const TiptapEditor = () => import('../../components/tiptap/TiptapEditor.vue' /* webpackChunkName: "components/tiptap-editor" */).then(c => wrapFunctional(c.default || c))
export const TiptapMenuButton = () => import('../../components/tiptap/TiptapMenuButton.vue' /* webpackChunkName: "components/tiptap-menu-button" */).then(c => wrapFunctional(c.default || c))
export const TiptapTableDialog = () => import('../../components/tiptap/TiptapTableDialog.vue' /* webpackChunkName: "components/tiptap-table-dialog" */).then(c => wrapFunctional(c.default || c))
export const VCardContent = () => import('../../components/vuetify/v-card-content.vue' /* webpackChunkName: "components/v-card-content" */).then(c => wrapFunctional(c.default || c))
export const VFileUpload = () => import('../../components/vuetify/v-file-upload.vue' /* webpackChunkName: "components/v-file-upload" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationDialog = () => import('../../components/course/dialogs/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionReportDialog = () => import('../../components/course/dialogs/CourseDescriptionReportDialog.vue' /* webpackChunkName: "components/course-description-report-dialog" */).then(c => wrapFunctional(c.default || c))
export const CourseDescriptionVersionDialog = () => import('../../components/course/dialogs/CourseDescriptionVersionDialog.vue' /* webpackChunkName: "components/course-description-version-dialog" */).then(c => wrapFunctional(c.default || c))
export const CourseFileReportDialog = () => import('../../components/course/dialogs/CourseFileReportDialog.vue' /* webpackChunkName: "components/course-file-report-dialog" */).then(c => wrapFunctional(c.default || c))
export const CourseFileUploadDialog = () => import('../../components/course/dialogs/CourseFileUploadDialog.vue' /* webpackChunkName: "components/course-file-upload-dialog" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBullets = () => import('../../components/landing/skeletons/SkeletonBullets.vue' /* webpackChunkName: "components/skeleton-bullets" */).then(c => wrapFunctional(c.default || c))
export const SkeletonHero = () => import('../../components/landing/skeletons/SkeletonHero.vue' /* webpackChunkName: "components/skeleton-hero" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
