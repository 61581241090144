import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _8986421c = () => interopDefault(import('../pages/fk/index.vue' /* webpackChunkName: "pages/fk/index" */))
const _afda8cc6 = () => interopDefault(import('../pages/landing.vue' /* webpackChunkName: "pages/landing" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _75d6015c = () => interopDefault(import('../pages/ping.vue' /* webpackChunkName: "pages/ping" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _d3569860 = () => interopDefault(import('../pages/statistics/index.vue' /* webpackChunkName: "pages/statistics/index" */))
const _1e33d5d6 = () => interopDefault(import('../pages/login/callback.vue' /* webpackChunkName: "pages/login/callback" */))
const _196a3d71 = () => interopDefault(import('../pages/agreements/_name/index.vue' /* webpackChunkName: "pages/agreements/_name/index" */))
const _7ecbe1f6 = () => interopDefault(import('../pages/course/_code/_tab/index.vue' /* webpackChunkName: "pages/course/_code/_tab/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about___en"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact___en"
  }, {
    path: "/fk",
    component: _8986421c,
    name: "fk___en"
  }, {
    path: "/landing",
    component: _afda8cc6,
    name: "landing___en"
  }, {
    path: "/nl",
    component: _2dfb1658,
    name: "index___nl"
  }, {
    path: "/ping",
    component: _75d6015c,
    name: "ping___en"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search___en"
  }, {
    path: "/statistics",
    component: _d3569860,
    name: "statistics___en"
  }, {
    path: "/login/callback",
    component: _1e33d5d6,
    name: "login-callback___en"
  }, {
    path: "/nl/about",
    component: _d8a8f054,
    name: "about___nl"
  }, {
    path: "/nl/contact",
    component: _1d238929,
    name: "contact___nl"
  }, {
    path: "/nl/fk",
    component: _8986421c,
    name: "fk___nl"
  }, {
    path: "/nl/landing",
    component: _afda8cc6,
    name: "landing___nl"
  }, {
    path: "/nl/ping",
    component: _75d6015c,
    name: "ping___nl"
  }, {
    path: "/nl/search",
    component: _6cfe81d6,
    name: "search___nl"
  }, {
    path: "/nl/statistics",
    component: _d3569860,
    name: "statistics___nl"
  }, {
    path: "/nl/login/callback",
    component: _1e33d5d6,
    name: "login-callback___nl"
  }, {
    path: "/nl/agreements/:name",
    component: _196a3d71,
    name: "agreements-name___nl"
  }, {
    path: "/nl/course/:code?/:tab",
    component: _7ecbe1f6,
    name: "course-code-tab___nl"
  }, {
    path: "/agreements/:name",
    component: _196a3d71,
    name: "agreements-name___en"
  }, {
    path: "/course/:code?/:tab",
    component: _7ecbe1f6,
    name: "course-code-tab___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
