import { computed, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import { optIn, optOut } from 'vue-gtag'

const allowCookies = ref<boolean>()

export default function useCookies() {
  if (Vue.$cookies.isKey('cookies_consent')) {
    allowCookies.value = Vue.$cookies.get('cookies_consent') === 'true'
    if (allowCookies.value === true) {
      optIn()
    }
  } else {
    allowCookies.value = undefined
  }

  watch(allowCookies, () => {
    if (allowCookies.value !== undefined) {
      Vue.$cookies.set('cookies_consent', allowCookies.value.toString(), '1y')
      if (allowCookies.value) {
        optIn()
      } else {
        optOut()
      }
    }
  })

  const showBanner = computed(() => {
    return allowCookies.value === undefined
  })

  const okClicked = () => (allowCookies.value = true)

  return {
    allowCookies,
    showBanner,
    okClicked,
  }
}
