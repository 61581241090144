// Copyright: (c) 2020-2021, VTK Gent vzw
// GNU Affero General Public License v3.0+ (see COPYING or https://www.gnu.org/licenses/agpl-3.0.txt)

import { Context } from '@nuxt/types'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { provide } from '@vue/composition-api'

export default (context: Context) => {
  context.app.setup = () => {
    const apolloClient = context.app.apolloProvider.defaultClient
    provide(DefaultApolloClient, apolloClient)
  }

  // Resolve HTTP Referer
  const referer = process.client ? window.document.referrer : `${context.$config.baseUrl}${context.req.url}`

  // Return configuration for default apollo client
  return {
    // Connect to backend hostname from inside the container
    httpEndpoint: `${context.$config.backendUrl}/graphql/`,
    // Set the Referer header for making requests
    httpLinkOptions: { headers: { referer } },
  }
}
