export default {
  landing: {
    title: 'Jouw deelplatform voor UGent vakken.',
    description:
      'Studium is hét platform voor educatieve tips en materialen die je helpen bij het verwerken van je vakken. Doe mee en start met bijdragen!',
    login: 'Inloggen met CAS',
    more: 'Meer informatie',
    special: 'Wat is zo speciaal?',
    know: 'Wil je nog meer weten?',
    free: 'Het is volledig gratis!',
    lang: 'Beschikbaar in zowel Nederlands als Engels, omdat we internationale studenten ook graag zien.',
    available: 'Ontworpen voor zowel grote als kleine schermen.',
    features: 'En veel meer functies die nog komen...',
  },

  navbar: {
    courses: 'Mijn vakken',
    search: 'Zoeken',
    about: 'Meer informatie',
  },

  courses: {
    subscribed: 'Subscribed',
    subscribe: 'Subscribe',
    description_t: 'Beschrijving',
    files_t: 'Bestanden',
    placeholder: {
      title: 'Je hebt nog geen cursussen opgeslagen',
      description: 'Klik hier om cursussen toe te voegen',
    },
    confirm: {
      sure: 'Ben je zeker?',
      confirm: 'Bevestig',
      cancel: 'Annuleer',
    },
    description: {
      description: 'Beschrijving',
      revisions: 'Revisies',
      edit: 'Bewerk',
      back: 'terug naar vak beschrijving',
      succes: 'Beschrijving succesvol geüpdatet.',
      nothing: 'Er lijkt nog geen beschrijving aanwezig te zijn',
      start: 'Start met het delen van je kennis!',
      wrong: 'Is er iets mis met deze bschrijving? Klik hier om dit te rapporteren!',
      report: {
        report: 'Rapporteer',
        subject: 'Beschrijving Gerapporteerd: ',
      },
      version: {
        revision_nr: 'Revisie #',
        reverted: 'Teruggezet van revisie #',
        revert: 'Zet terug ',
        tooltip: 'De beschrijving zal terug gezet worden naar deze revisie',
        close: 'Sluit',
        card: {
          by: 'Door ',
          reverted: 'Teruggezet van revisie #',
          show: 'Toon',
          revision: 'revisie',
          changes: 'Veranderingen',
        },
        list: {
          not_available: 'Er zijn nog geen revisies beschikbaar',
        },
      },
      editor: {
        save: 'Bewaar',
        cancel: 'Annuleer',
      },
    },
    files: {
      files: 'Bestanden',
      upload_t: 'Upload',
      succes: 'Bestand is succesvol geüpload',
      search_label: 'Zoek naar een bestand',
      date: 'Datum',
      name: 'Naam',
      downloads: 'Downloads',
      ratings: 'Waarderingen',
      not_available: 'Dit vak heeft nog geen bestanden. ',
      upload_file: 'Upload een bestand!',
      no_results: 'Geen resultaten gevonden... Probeer andere zoektermen. ',
      report: {
        report: 'Rapporteer',
      },
      upload: {
        upload_file: 'Upload een bestand',
        hint: 'Kies een representatieve naam die de inhoud van het bestand weergeeft',
        name_label: 'Naam',
        multiple_files: 'Bij het uploaden van meerdere bestanden moet elk bestand van dezelfde categorie zijn.',
        category_label: 'Selecteer een categorie',
        anonymous_label: 'Anoniem',
        anonymous_hint: 'Stuur het bestand in als een anonieme gebruiker',
        anonymous_info:
          'Admins kunnen nog steeds zien wie de bestanden uploadt en kunnen bij inbreuk op regels jouw toegang tot het platform ontzeggen.',
        upload: 'Upload',
        select: 'Selecteer een bestand om verder te gaan',
        cancel: 'Annuleer',
        uploading: 'Uploaden - ',
        upload_comp: 'Upload in orde voor ',
        files: ' bestand(en)',
        error_upload: 'Het uploaden is mislukt voor ',
        done: 'Klaar',
      },
      card: {
        by: 'door',
        report: 'Rapporteer',
        delete: 'Verwijder',
        delete_sure: 'Ben je zeker dat je dit bestand wilt verwijderen: ',
      },
    },
  },

  about: {
    title1: 'Sharing is caring!',
    what: "Studium is een platform waar studenten van de Universiteit van Gent hun lesnotities, samenvattingen en meer kunnen delen met andere studenten. Het doel is om dit zo simpel mogelijk en bovenal gratis te doen! Origineel gestart door Karel D'Oosterlinck, Silas Deblanc en Maxim De Clercq, dit platform is gecreeerd door VTK, de Genste Studentenraad en FK.",
    title_who: 'Door wie?',
    vtk_info:
      'De Vlaamse Technische Kring Gent vzw is de studentenkring voor studenten burgerlijk ingenieur en burgerlijk ingenieur-architect aan de Faculteit Ingenieurswetenschappen en Architectuur van de Universiteit Gent. VTK kan gecontacteerd worden via info@vtk.ugent.be.',
    vtk_title: 'VTK',
    gsr_title: 'Gentse Studentenraad',
    gsr_info:
      'De Gentse Studentenraad is de centrale studentenraad van de Universiteit Gent. De Gentse Studentenraad representeert en verdedigt de belangen van de studenten. De onderwerpen variëren enorm van onderwijs (Examen reglementering, speciaal statuut voor studenten ...) maar ook sociale zaken (huizingm studentenrestaurants ...)  en nog veel meer. De lijst van onderwerpen is groot en divers en niet gelimiteerd tot voorgenoemde onderwerpen. Echter is de Gentse Studentenraard niet enkel actief op universitair niveau. Bovenop het steunen van de Favultaire Studentenraden waar er elf van bestaan, is de Gentse Studentenraad ook actief op provinciaal en Vlaams niveau. Tenslotte steunt de Gentse Studentenraad meerdere kleine en grote projectn zoals de Fietsambassade, Student Kick-Off en Studium. Geïnteresseerd of heb je vragen? Contacteer ons via volgend emailadres: info@gentsestudentenraad.be.',
    fk_title: 'FK',
    fk_info:
      'Het FaculteitenKonvent (FK) is de koepel van de 29 opleidingsgebonden studentenkringen aan de Universiteit Gent. Dit zijn de studentenverenigingen die verbonden zijn aan een specifieke opleiding of faculteit. In totaal spreken we zo van een ledenaantal dat reeds de 18000 overstijgt. De verschillende kringen die onder het FaculteitenKonvent vallen, hebben de voorbije jaren hun werking sterk weten te professionaliseren. Al deze verenigingen bieden een breed gamma aan activiteiten aan en trachten zoveel mogelijk in te spelen op de noden van de studenten. Academische vorming, sociaal engagement en ontspanning gaan hand in hand bij de kringen en daar zijn wij oprecht trots op. Geïnteresseerd of heb je vragen? Contacteer ons via volgend emailadres: info@fkgent.be.',
    faq_title: 'FAQ',
    faq_q_1: 'Is Studium toegankelijk voor iedereen aan de Universiteit van Gent',
    faq_a_1: 'Je, Studium is sinds het begin van academiejaar 2022-2023 toegankelijk voor de hele universiteit.',
    faq_q_2: 'Is Studium toegankelijk voor mensen buiten de Universiteit van Gent?',
    faq_a_2:
      'Nee, op dit moment zijn er geen plannen om dit platform toegankelijk te maken voor mensen buiten de universiteit.',
    faq_q_3: 'Mijn vak heeft nog geen bestanden?',
    faq_a_3:
      'Het uploaden van bestanden wordt door de studenten zelf gedaan. Om de transitie makkelijker te maken tussen Studium en het vorig platform van jullie studentenvereniging, zal jullie studentenvereniging alle bestanden overzetten naar Studium. Als er bestanden ontbreken, gelieve een bericht naar hen te sturen.',
    faq_q_4: 'Wordt Studium nog steeds geupdatet?',
    faq_a_4: 'Ja, Studium wordt nog steeds geupdatet met bug fixes en nieuwigheden.',
    contact_title: 'Contacteer',
    contact_info: 'Om Studium te contacteren of voor vragen, stuur een mail naar: contact@studium.gent .',
  },

  agreement: {
    title: 'Privacy Policy en Terms of Service',
    desc_welcome: 'Welkom bij Studium!',
    desc: 'Voor je van start kan gaan, moet je eerst de Privacy Policy en Terms of Service doorlezen en goedkeuren.',
    agreed: 'Ik heb gelezen en keur goed de ',
    terms: 'Terms of Service',
    open_terms: 'Open terms of services',
    privacy: 'Privacy Policy',
    open_privacy: 'Open privacy agreement',
    accept: 'Ik accepteer',
    check_all: 'Vink alle vakjes aan om door te gaan',
  },

  cookies: {
    info: 'Wij gebruiken cookies om ons verkeer te analyseren en de functionaliteit van deze website te verbeteren. Voor meer informatie,',
    click: 'klik hier',
    accept: 'Accepteer alles',
    decline: 'Weiger',
  },

  contact: {
    sent: 'Je bericht is verzonden.',
    send: 'Verstuur',
    subject: 'Onderwerp',
    message: 'Bericht',
    title: 'Contacteer',
  },

  report: {
    sent: 'Je rapportering is verstuurd.',
    send: 'Verstuur',
    message: 'Bericht',
  },

  document: {
    close: 'Sluit',
  },

  flatpage: {
    last: 'Laatst geüpdate op ',
  },

  feedback: {
    info: 'We werken nog steeds om Studium te verbeteren. Alle feedback of problemen zijn meer dan welkom!',
    give: 'Geef feedback',
    no: 'Nee bedankt',
  },

  footbar: {
    about: 'Over ons',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    cookies: 'Cookies',
    source: 'Source Code',
    discord: 'Discord Community',
    contact: 'Contacteer',
  },

  upload: {
    drop: 'Sleep bestanden om te uploaden of ',
    browse: 'blader',
  },

  error: {
    return: 'Ga terug naar de home pagina',
  },

  search: {
    search_label: 'Zoek naar een vak',
    faculty: 'Faculteit',
    faculty_need: 'Je hoeft geen faculteit te selecteren als je al een programma hebt geselecteerd ',
    program: 'Programma',
    year: 'Jaar',
    program_before: 'Selecteer eerst een prgramma alvorens een jaar te selecteren ',
    semesters: 'Semester',
    program_semester: 'Selecteer eerst een programma alvorens een semester te selecteren',
    initial: 'Zoek naar een vak of voeg enkele filters toe...',
    no_courses: 'Er zijn geen vakken gevonden met deze zoektermen',
    number: 'Aantal',
  },

  index: {
    no_courses: 'Je hebt nog geen vakken opgeslagen',
    add_courses: 'Voeg hier je vakken toe ',
    my_courses: 'Mijn vakken',
  },

  statistics: {
    title: 'Statistieken',
    description:
      'Op deze pagina zijn enkele statistieken over Studium te vinden. Het doel van deze statistieken is het bepalen hoe relevant en populair Studium nog is op dit tijdstip. Op dit moment kan je statistieken zien van de laatste 5 weken/maanden.',
    general: {
      title: 'Algemene statistieken',
      users: 'Totaal aantal gebruikers',
      documents: 'Totaal aantal geüploadde documenten',
    },
    weekly: {
      title: 'Wekelijkse statistieken',
      new_users: 'Aantal nieuwe gebruikers',
      new_documents: 'Aantal nieuwe documenten',
      downloads_count: 'Aantal downloads',
      descriptions_count: 'Aantal aangepaste beschrijvingen',
      period: 'Week',
    },
    monthly: {
      title: 'Maandelijkse statistieken',
      period: 'Maand',
    },
    comparison: {
      title: 'Vergelijk Studentenverenigingen',
      since: 'Sinds jaar: ',
      year: 'Sinds jaar',
      send: 'Haal op',
    },
  },
}
