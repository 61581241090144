import { computed, nextTick, Ref, ref, useRoute, useRouter } from '@nuxtjs/composition-api'

/**
 * Vue composable for using a router target param as a reactive boolean model.
 * @param name Name of the target parameter to use.
 */
export function useRouterHash(name: string): Ref<boolean> {
  const route = useRoute()
  const router = useRouter()

  // Previous value
  // This is necessary as Vuetify modals sometimes trigger the close action twice
  const previousValue = ref(false)

  return computed<boolean>({
    get() {
      return route.value.hash === `#${name}`
    },
    set(value) {
      nextTick(() => {
        const newRoute = { hash: name }

        // Update the route
        // If the value is false, remove the router hash from the URL and from the history.
        if (!value && previousValue.value !== value) {
          // Delete the hash when the given value is false
          newRoute.hash = ''

          // Override the previous route, which contains the hash.
          router.back()
        }
        // If the value is true, push a new state with the router hash.
        else {
          router.push(newRoute)
        }

        // Update the previous value
        previousValue.value = value
      })
    },
  })
}
