import { computed, nextTick, Ref, useRoute, useRouter } from '@nuxtjs/composition-api'

interface UseRouterQueryOptions {
  /**
   * Function to determin if the given query is empty and should be removed from the URL.
   */
  isEmpty?: (value: any) => boolean
}

/**
 * Vue composable for using a router query param as a reactive model.
 * @param name Name of the query parameter to use.
 * @param defaultValue Optional default value in case the query parameter is not present.
 */
export function useRouterQuery<T>(name: string, defaultValue?: T, options?: UseRouterQueryOptions): Ref<T | string> {
  const route = useRoute()
  const router = useRouter()

  return computed<any>({
    get() {
      const data = route.value.query[name]

      // Apply default value
      if (data === null || data === undefined) {
        return defaultValue
      }

      if (typeof defaultValue === 'number') {
        return parseInt('' + data)
      }
      return data
    },
    set(value) {
      nextTick(() => {
        const newRoute = { query: { ...route.value.query, [name]: value } }

        // Delete the query parameter when the value is null
        if ((options?.isEmpty && options?.isEmpty(value)) || value === null) {
          delete newRoute.query[name]
        }

        router.replace(newRoute)
      })
    },
  })
}
