import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=647ff6cb&xmlns%3Athe-=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./NavBar.vue?vue&type=script&lang=ts&"
export * from "./NavBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheLogo: require('/app/components/layout/TheLogo.vue').default,UserAvatar: require('/app/components/layout/UserAvatar.vue').default,LanguageSelector: require('/app/components/language/LanguageSelector.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VContainer,VIcon,VSpacer})
