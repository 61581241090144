export default {
  landing: {
    title: 'Your course sharing platform.',
    description:
      'Studium is the platform for educational tips and materials that will help you process your courses. Get involved and start contributing! ',
    login: 'Sign in with CAS',
    more: 'Learn more',
    special: "What's so special?",
    know: 'Need to know more?',
    free: "It's completely free!",
    lang: 'In both Dutch and English, because we love international students.',
    available: 'Designed for both desktop and mobile.',
    features: 'Many more features yet to come...',
  },

  navbar: {
    courses: 'My Courses',
    search: 'Search',
    about: 'About',
  },

  courses: {
    subscribed: 'Subscribed',
    subscribe: 'Subscribe',
    description_t: 'Description',
    files_t: 'Files',
    placeholder: {
      title: "You don't have any courses saved yet",
      description: 'Click here to add your courses',
    },
    confirm: {
      sure: 'Are you sure?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    description: {
      description: 'Description',
      revisions: 'Revisions',
      edit: 'Edit',
      back: 'back to course description',
      succes: 'Description successfully updated.',
      nothing: 'There seems to be nothing here',
      start: 'Start sharing your knowledge!',
      wrong: 'Something wrong with this description? Click me to report!',
      report: {
        report: 'Report',
        subject: 'Description Report: ',
      },
      version: {
        revision_nr: 'Revision #',
        reverted: 'Reverted from revision #',
        revert: 'Revert ',
        tooltip: 'The description will be restored to this revision',
        close: 'Close',
        card: {
          by: 'By ',
          reverted: 'Reverted from revision #',
          show: 'Show',
          revision: 'revision',
          changes: 'Changes',
        },
        list: {
          not_available: 'No revisions are available yet',
        },
      },
      editor: {
        save: 'Save',
        cancel: 'Cancel',
      },
    },
    files: {
      files: 'Files',
      upload_t: 'Upload',
      succes: 'File has been successfully uploaded',
      search_label: 'Search for a file',
      date: 'Date',
      name: 'Name',
      downloads: 'Downloads',
      ratings: 'Ratings',
      not_available: 'No files available for this course. ',
      upload_file: 'Upload a file!',
      no_results: 'No results found... Try searching something else. ',
      report: {
        report: 'Report',
      },
      upload: {
        upload_file: 'Upload a file',
        hint: 'Choose a descriptive name that is representable of the content of the file',
        name_label: 'Name',
        multiple_files: 'When uploading multiple files at once, all files must be of the same category.',
        category_label: 'Select a category',
        anonymous_label: 'Anonymous',
        anonymous_hint: 'Post the data as an anonymous user.',
        anonymous_info: 'Admins will still see who uploaded a file and may void your access on abuse.',
        upload: 'Upload',
        select: 'Select a file to continue',
        cancel: 'Cancel',
        uploading: 'Uploading - ',
        upload_comp: 'Upload completed for ',
        files: ' files(s)',
        error_upload: "We couldn't upload these ",
        done: 'Done',
      },
      card: {
        by: 'by',
        report: 'Report',
        delete: 'Delete',
        delete_sure: 'Are you sure you want to delete ',
      },
    },
  },

  about: {
    title1: 'Sharing is caring!',
    what: "Studium is a platform where students of the University of Ghent are able to share their own course notes, summaries and more with other students. The goal is to make it simple, clean and above all free! Originally created by Karel D'Oosterlinck, Silas Deblanc and Maxim De Clercq, this platform is created together with VTK, Ghent Student Council and FK.",
    title_who: 'By who?',
    vtk_title: 'VTK',
    vtk_info:
      'VTK Ghent, the Flemish Technical Society, is the student society for engineering students at the Faculty of Engineering and Architecture at the University of Ghent. VTK can be contacted via info@vtk.ugent.be.',
    gsr_title: 'Ghent Student Council',
    gsr_info:
      'The Ghent Student Council is the central student council of Ghent University. The Ghent Student Council represents and defends the interests of the students. The topics range from all affairs concerning education (Education and Examination code, special status for students ...) as well as social affairs (housing, student restaurants ...) and many other topics. The list of topics is quite diverse and is not limited to the aforementioned. However, the Ghent Student Council is not solely active on the university level. As well as supporting the Faculty Student Councils of which there are eleven, the Ghent Student Council is also active on both the municipal and Flemish level. Finally, the Ghent Student Council supports several smaller and a few big projects such as the Fietsambassade, the Student Kick-Off and Studium. Are you interested or do you have questions? Feel free to contact us through email via info@gentsestudentenraad.be.',
    fk_title: 'FK',
    fk_info:
      "The FaculteitenKonvent (FK) is the overarching organisation of the 29 faculty-bound student societies of Ghent University. These are the student societies dedicated to a specific faculty or course at the University. The FK currently boasts a membership count in excess of 18000. The different societies which are part of the FaculteitenKonvent, have professionalized their workings in recent years. All these societies offer a broad gamut of activities and try to cater to students' needs as much as possible. Academic education, social engagement and recreation go hand in hand. Any questions or do you want to contact us? Fk can be contacted via info@fkgent.be.",
    faq_title: 'FAQ',
    faq_q_1: 'Is Studium available to the entire University of Ghent?',
    faq_a_1:
      'Yes, we launched the website at the beginning of the academic year 2022-2023 for students at the University of Ghent.',
    faq_q_2: 'Is Studium available to people outside of the University of Ghent?',
    faq_a_2: 'No, currently there are no plans to extend this platform to people outside of the university.',
    faq_q_3: 'My course does not have any files?',
    faq_a_3:
      'The uploading of files is done by the students themselves. To make the transition easier between Studium and your previous platform from your student society, your student society will transfer all files from their platform to ours. If files are missing, please contact them.',
    faq_q_4: 'Is Studium still being updated?',
    faq_a_4: 'Yes, Studium is still being updated with bug fixes and new features.',
    contact_title: 'Contact',
    contact_info:
      'To contact Studium for information or other enquiries, please send an email to contact@studium.gent .',
  },

  agreement: {
    title: 'Privacy Policy and Terms of Service',
    desc_welcome: 'Welcome to Studium!',
    desc: 'Before you get started, you need to agree to our Privacy Policy and Terms of Service.',
    agreed: 'I have read and agreed to the',
    terms: 'Terms of Service',
    open_terms: 'Open terms of services',
    privacy: 'Privacy Policy',
    open_privacy: 'Open privacy agreement',
    accept: 'I accept',
    check_all: 'Check all boxes to continue',
  },

  cookies: {
    info: 'We use cookies to analyse our traffic and further improve the functionality of this website. For more information,',
    click: 'click here',
    accept: 'Accept all',
    decline: 'Decline',
  },

  contact: {
    sent: 'Your message has been sent.',
    send: 'Send',
    subject: 'Subject',
    message: 'Message',
    title: 'Contact',
  },

  report: {
    sent: 'Your report has been sent.',
    send: 'Send',
    message: 'Message',
  },

  document: {
    close: 'Close',
  },

  flatpage: {
    last: 'Last updated on ',
  },

  feedback: {
    info: 'We are still working in order to improve Studium. Any feedback or bug reports would be very helpful!',
    give: 'Give feedback',
    no: 'No, Thanks',
  },

  footbar: {
    about: 'About',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    cookies: 'Cookies',
    source: 'Source Code',
    discord: 'Discord Community',
    contact: 'Contact',
  },

  upload: {
    drop: 'Drop files to upload or ',
    browse: 'browse',
  },

  error: {
    return: 'Return to homepage',
  },

  search: {
    search_label: 'Search for a course',
    faculty: 'Faculty',
    faculty_need: "You don't need to select a faculty when a program is already selected ",
    program: 'Program',
    year: 'Year',
    program_before: 'Select a program before selecting a year ',
    semesters: 'Semesters',
    program_semester: 'Select a program before selecting a semester',
    initial: 'Search for a course or add some filters...',
    no_courses: 'No courses found with the given search terms',
    number: 'Number',
  },

  index: {
    no_courses: "You don't have any courses saved yet",
    add_courses: 'Add your courses here ',
    my_courses: 'My courses',
  },

  statistics: {
    title: 'Statistics',
    description:
      'On this page, some statistics of Studium are given. The goal of these statistics is to help decide the current state and popularity of Studium. At this moment, you can find statistics from the last 5 weeks/months.',
    general: {
      title: 'General statistics',
      users: 'Total number of users',
      documents: 'Total number of documents uploaded',
    },
    weekly: {
      title: 'Weekly statistics',
      new_users: 'Number of new users',
      new_documents: 'Number of new documents',
      downloads_count: 'Number of downloads',
      descriptions_count: 'Number of altered descriptions',
      period: 'Week',
    },
    monthly: {
      title: 'Monthly statistics',
      period: 'Month',
    },
    comparison: {
      title: 'Comparison Student Societies',
      since: 'Since year: ',
      year: 'Since year',
      send: 'Get',
    },
  },
}
