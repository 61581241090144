import { ErrorResponse } from '@apollo/client/link/error'
import { ServerParseError } from '@apollo/client/link/http'
import { ServerError } from '@apollo/client/link/utils'
import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { UseMutationReturn, UseQueryReturn } from '@vue/apollo-composable'
import { GraphQLError } from 'graphql'

export interface CustomError {
  /**
   * Error message
   */
  message: string

  /**
   * If the error is derived from a network error.
   */
  isNetworkError: boolean

  /**
   * If the error is derived from a GraphQL error.
   */
  isGraphQLError: boolean

  /**
   * If the error is derived from an authentication error.
   */
  isAuthError: boolean

  /**
   * Original error
   */
  originalError: ErrorResponse | null
}

export interface ErrorHandlerOptions {
  /**
   * Custom handler in case of a network error.
   * This handler is called when the user does not have a network connection or the server is offline.
   *
   * If no handler is provided, the default one will be used (redirect to error page)
   */
  onNetworkError?: (error: CustomError, networkError: Error | ServerError | ServerParseError) => CustomError

  /**
   * Custom handler in case of a GraphQL error.
   * This handler is called when a GraphQL error occurs.
   */
  onGraphQLErrors?: (error: CustomError, graphqlErrors: ReadonlyArray<GraphQLError>) => CustomError

  /**
   * Enable redirect to error page on a network error.
   */
  networkErrorRedirect?: boolean
}

/**
 * Vue composable for handling GraphQL errors.
 * @param observable Query/Mutation result
 * @param options Options
 */
export function useErrorHandler<TResult, TVariables>(
  observable: UseMutationReturn<TResult, TVariables> | UseQueryReturn<TResult, TVariables>,
  options: ErrorHandlerOptions = {}
): ComputedRef<CustomError | null> {
  const context = useContext()

  return computed(() => {
    return handleError(observable.error.value as unknown as ErrorResponse, context, options)
  })
}

/**
 * Convert a GraphQL error to a custom error.
 *
 * @param error Error
 * @param context Nuxt Context
 * @param options Options
 */
export function handleError(error: any, context: any, options: ErrorHandlerOptions = {}): CustomError | null {
  // Return null when no error is present
  if (!error) {
    return null
  }

  // eslint-disable-next-line no-console
  console.error(error)

  let customError = {
    message: 'Unknown Error',
    isNetworkError: false,
    isGraphQLError: false,
    isAuthError: false,
    originalError: error,
  } as CustomError

  // If an authentication error occurred.
  if (error.name === 'ExpiredAuthSessionError') {
    customError.message = 'Your session has expired. Please log in again.'
    customError.isAuthError = true

    return customError
  }

  // If a network error occurred.
  if (error.networkError) {
    // If the user is offline
    if (process.client && window.$nuxt.isOffline) {
      customError.message = 'Unable to connect. Make sure you have a valid internet connection.'
    } else {
      customError.message = 'Unable to connect. Please try again later.'
    }

    customError.isNetworkError = true

    // Execute network error handler, if rpesent.
    if (options.onNetworkError) {
      customError = options.onNetworkError(customError, error.networkError)
    }

    // Redirect to error page, if enabled
    if (options.networkErrorRedirect) {
      context.error({
        message: customError.message,
        statusCode: 503,
      })
    }

    return customError
  }

  // If a GraphQL error occurred.
  if (error.graphQLErrors) {
    // Take the first GraphQL error with a valid message
    let errorMessage = error.graphQLErrors.find((graphQLError) => graphQLError.message)?.message || 'Unknown error'

    // Some backend error message contain JSON, convert those to the value of the first key.
    // This is a workaround for the fact that backend error messages are not always properly formatted.
    try {
      errorMessage = String(Object.values(JSON.parse(errorMessage))[0]) || 'Unknown Error'
    } catch (e) {}

    customError.message = errorMessage
    customError.isGraphQLError = true

    // Execute GraphQL error handler, if present
    if (options.onGraphQLErrors) {
      customError = options.onGraphQLErrors(customError, error.graphQLErrors)
    }

    return customError
  }

  return customError
}
