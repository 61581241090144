// Copyright: (c) 2020-2021, VTK Gent vzw
// GNU Affero General Public License v3.0+ (see COPYING or https://www.gnu.org/licenses/agpl-3.0.txt)

import type {
  HTTPResponse,
  RefreshableScheme,
  RefreshableSchemeOptions,
  SchemeCheck,
  SchemeOptions,
  SchemePartialOptions,
  TokenableSchemeOptions,
} from '@nuxtjs/auth-next'
import type { Auth } from '@nuxtjs/auth-next/dist/runtime'
import {
  BaseScheme,
  ExpiredAuthSessionError,
  RefreshController,
  RefreshToken,
  Token,
} from '@nuxtjs/auth-next/dist/runtime'
import { ApolloClient, ApolloQueryResult, MutationOptions, QueryOptions } from 'apollo-client'
import { FetchResult } from 'apollo-link'
import { DocumentNode } from 'graphql'
import requrl from 'requrl'

import { CasTokenAuthDocument, MeDocument, RefreshTokenDocument, RevokeTokenDocument } from '~/apollo/operations'
import { CasHandler } from '~/auth/cas-handler'
import { getProp, normalizePath, parseQuery, urlJoin } from '~/auth/utils'

export interface CasSchemeOptions extends SchemeOptions, TokenableSchemeOptions, RefreshableSchemeOptions {
  ticket: {
    property: string
  }
  target: {
    property: string
  }
  user: {
    property: string
  }
  redirects: {
    login: string
    logout: string
  }
  queries: {
    login: DocumentNode
    logout: DocumentNode
    refresh: DocumentNode
    user: DocumentNode
  }
}

// Define options here since it is not required to have this scheme modifiable
// DOCS: https://auth.nuxtjs.org/schemes/refresh/
const DEFAULTS: SchemePartialOptions<CasSchemeOptions> = {
  name: 'cas',
  ticket: {
    property: 'ticket',
  },
  target: {
    property: 'target',
  },
  token: {
    property: 'token',
    type: 'Bearer',
    name: 'Authorization',
    // Set default age to 15 minutes if the expiry time can't be decoded
    maxAge: 15 * 60,
    global: true,
    prefix: '_token.',
    expirationPrefix: '_token_expiration.',
  },
  refreshToken: {
    property: 'refreshToken',
    // Set default age to 7 days if the expiry time can't be decoded
    maxAge: 7 * 24 * 60 * 60,
    prefix: '_refresh_token.',
    expirationPrefix: '_refresh_token_expiration.',
  },
  user: {
    property: 'me',
  },
  redirects: {
    login: 'https://login.ugent.be/login',
    logout: 'https://login.ugent.be/logout',
  },
  queries: {
    login: CasTokenAuthDocument,
    logout: RevokeTokenDocument,
    refresh: RefreshTokenDocument,
    user: MeDocument,
  },
}

export default class CasScheme<OptionsT extends CasSchemeOptions = CasSchemeOptions>
  extends BaseScheme<OptionsT>
  implements RefreshableScheme<OptionsT>
{
  // @ts-ignore
  public token: Token
  // @ts-ignore
  public refreshToken: RefreshToken
  // @ts-ignore
  public refreshController: RefreshController
  // @ts-ignore
  public requestHandler: CasHandler

  public $apolloClient: ApolloClient<any>

  constructor(
    $auth: Auth,
    options: SchemePartialOptions<CasSchemeOptions>,
    ...defaults: SchemePartialOptions<CasSchemeOptions>[]
  ) {
    // @ts-ignore
    super($auth, options as OptionsT, ...(defaults as OptionsT[]), DEFAULTS as OptionsT)

    // Initialize Token instance
    // @ts-ignore
    this.token = new Token(this, $auth.$storage)

    // Initialize Refresh Token instance
    // @ts-ignore
    this.refreshToken = new RefreshToken(this, $auth.$storage)

    // Initialize Refresh Controller
    // @ts-ignore
    this.refreshController = new RefreshController(this)

    // Initialize Apollo middleware
    // @ts-ignore
    this.requestHandler = new CasHandler($auth)

    // Set Apollo property shortcuts
    this.$apolloClient = $auth.ctx.app.apolloProvider.defaultClient
  }

  async mounted(): Promise<HTTPResponse | void> {
    // Check the validity of the tokens
    const { refreshTokenExpired } = this.check(true)

    // Force reset if refresh token has expired
    if (refreshTokenExpired) this.$auth.reset()

    // Initialize request interceptor
    this.requestHandler.initializeApolloLink(this.options.queries.refresh)

    // Handle a possible callback do nothing else if the callback caused a redirection
    const redirected = await this.handleCallback()
    if (redirected) return

    // The request was not a callback so fetch the user for this page
    return await this.$auth.fetchUserOnce()
  }

  reset(): void {
    // Reset user
    this.setUser(false)
    // Reset tokens and handler
    this.token.reset()
    this.refreshToken.reset()
    this.requestHandler.reset()
  }

  /**
   * Login
   */
  async login(): Promise<void> {
    // Ditch any leftover local tokens before attempting to log in
    await this.$auth.reset()

    // Redirect to the authentication endpoint when trying to log in
    // Do not try to login when debugging, use a fake ticket instead
    const url = !this.$auth.ctx.$config.mockCas
      ? `${this.options.redirects.login}?service=${this.loginCallbackUrl}`
      : `${this.loginCallbackUrl}?ticket=ST-00000000`
    window.location.replace(url)
  }

  /**
   * Logout
   */
  async logout(): Promise<void> {
    // Revoke the refresh token if it is still valid
    const { refreshTokenExpired } = this.check(true)
    if (!refreshTokenExpired) {
      await this.mutate({
        mutation: this.options.queries.logout,
        variables: {
          refreshToken: this.refreshToken.sync(),
        },
      }).catch(() => {}) // Ignore errors and just remove tokens
    }

    // Reset all tokens (this will redirect to the landing page automatically)
    return this.$auth.reset()
  }

  /**
   * Set the auth token and optionally the refresh token,
   * then it will fetch the user using the new token and current strategy.
   */
  // eslint-disable-next-line require-await
  async setUserToken(token: string | boolean, refreshToken?: string | boolean): Promise<HTTPResponse | void> {
    // Skip if no token is supplied
    if (typeof token !== 'string' || token.length === 0) return
    this.token.set(token)
    // Skip if no refresh token is supplied
    if (typeof refreshToken !== 'string' || refreshToken.length === 0) return
    this.refreshToken.set(refreshToken)
  }

  /**
   * Set user universally (client-side and server-side).
   */
  setUser(user): void {
    this.$auth.$storage.setUniversal('user', user)
    let check = { valid: !!user }
    if (check.valid) check = this.check()
    this.$auth.$storage.setUniversal('loggedIn', check.valid)
  }

  /**
   * Force re-fetch user using active strategy.
   */
  async fetchUser(): Promise<void> {
    // We can not query the user if the tokens are not valid
    if (!this.check().valid) return

    // Query the user
    return await this.query({
      query: this.options.queries.user,
    }).then((response) => {
      // Save the queried user
      const user = getProp(response.data, this.options.user.property)
      this.setUser(user)
    })
  }

  /**
   * Refreshes tokens if refresh token is available and not expired.
   * This only works when logged in.
   */
  async refreshTokens(): Promise<HTTPResponse | void> {
    // Get refresh token
    const refreshToken = this.refreshToken.get()

    // Refresh token is required but not available
    if (!refreshToken) return

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset()
      this.$auth.callOnError(new ExpiredAuthSessionError(), {})
      throw new ExpiredAuthSessionError()
    }

    // Delete current token from the request header before refreshing
    await this.requestHandler.clearHeader()

    // Refresh token
    const response = await this.mutate({
      mutation: this.options.queries.refresh,
      variables: {
        refreshToken,
      },
    })

    // Find mutation property
    const refresh = this.options.queries.refresh.definitions[0]
    const refreshProp: string = 'name' in refresh ? (refresh.name?.value as string) : ''

    // Parse token and refresh token
    const newTokenProp: string = this.options.token.property as string
    const newToken: string = response.data[refreshProp][newTokenProp] as string
    const newRefreshTokenProp: string = this.options.refreshToken.property as string
    const newRefreshToken: string = response.data[refreshProp][newRefreshTokenProp] as string

    // Skip if no token provided
    if (!newToken || !newToken.length) return

    // Save tokens
    await this.$auth.setUserToken(newToken, newRefreshToken)
  }

  /**
   * Check if the tokens are valid.
   */
  check(checkStatus = false): SchemeCheck {
    // Check the validity of the tokens
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true,
    }

    // Sync tokens
    const token = this.token.sync()
    const refreshToken = this.refreshToken.sync()

    // Token and refresh token are required but not available
    if (!token || !refreshToken) {
      return response
    }

    // Check status wasn't enabled, let it pass
    if (!checkStatus) {
      response.valid = true
      return response
    }

    // Get status
    const tokenStatus = this.token.status()
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token has expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      response.refreshTokenExpired = true
      return response
    }

    // Token has expired, Force reset.
    if (tokenStatus.expired()) {
      response.tokenExpired = true
      return response
    }

    // Token and refresh token are still valid
    response.valid = true
    return response
  }

  /**
   * Login callback URL
   */
  private get loginCallbackUrl() {
    const basePath = this.$auth.ctx.base || ''
    const path = normalizePath(basePath + '/' + this.$auth.options.redirect.callback) // Don't pass in context since we want the base path
    return urlJoin(requrl(this.$auth.ctx.req), path)
  }

  /**
   * Handle a potential callback, received from the login endpoint.
   */
  private async handleCallback(): Promise<boolean> {
    // Handle callback only for specified route
    if (
      this.$auth.options.redirect &&
      normalizePath(this.$auth.ctx.route.path, this.$auth.ctx) !==
        normalizePath(this.$auth.options.redirect.callback, this.$auth.ctx)
    ) {
      return false
    }

    // Parse query parameters
    const hash = parseQuery(this.$auth.ctx.route.hash.slice(1))
    const parsedQuery = Object.assign({}, this.$auth.ctx.route.query, hash)
    const ticket: string = parsedQuery[this.options.ticket.property] as string
    const target: string = parsedQuery[this.options.target.property] as string

    // Consume query parameters (these shouldn't persist after redirecting)
    delete this.$auth.ctx.query.target
    delete this.$auth.ctx.query.ticket

    // Do nothing if no ticket was provided
    if (!ticket || !ticket.length) return false

    // Authenticate the user if this wasn't the case already (e.g. on server-side)
    // Checking against this prevents the ticket from being verified multiple times
    if (!this.$auth.loggedIn) {
      // Authenticate against the backend
      const response = await this.mutate({
        mutation: CasTokenAuthDocument,
        variables: { ticket, service: this.loginCallbackUrl },
      })
      // Set tokens from response and do nothing if something is wrong
      const valid = await this.setTokensFromResponse(response)
      if (!valid) return false
    }

    // Redirect to target or home (true means a redirect happened)
    this.$auth.redirect(target || 'home')
    return true
  }

  /**
   * Set the user tokens, received from a specific response.
   */
  private async setTokensFromResponse(response: FetchResult): Promise<boolean> {
    // Return if there is no data to parse
    if (!response.data) return false

    // Parse token and refresh token
    const queryProp: string = Object.keys(response.data)[0] as string
    const tokenProp: string = this.options.token.property as string
    const token: string = response.data[queryProp][tokenProp] as string
    const refreshTokenProp: string = this.options.refreshToken.property as string
    const refreshToken: string = response.data[queryProp][refreshTokenProp] as string

    // Skip if no token provided
    if (!token || !token.length) return false

    // Save tokens
    await this.$auth.setUserToken(token, refreshToken)
    return true
  }

  /**
   * Mimics the behavior of the original request method but with Apollo
   */
  private query(options: QueryOptions<any>): Promise<HTTPResponse | ApolloQueryResult<any>> {
    if (!this.$apolloClient) {
      // eslint-disable-next-line no-console
      console.error('[AUTH] add the @nuxtjs/apollo module to nuxt.config file')
    }

    // Execute the query with Apollo
    return this.$apolloClient.query(options).catch((error) => {
      // Call all error handlers
      // For some reason (either a bug in Nuxt Auth or Apollo), the error is not passed, unless wrapped in a setTimeout
      setTimeout(() => this.$auth.callOnError(error, { method: 'query' }), 0)

      // Throw error
      return Promise.reject(error)
    })
  }

  /**
   * Mimics the behavior of the original request method but with Apollo
   */
  private mutate(options: MutationOptions<any>): Promise<HTTPResponse | FetchResult<any>> {
    if (!this.$apolloClient) {
      // eslint-disable-next-line no-console
      console.error('[AUTH] add the @nuxtjs/apollo module to nuxt.config file')
    }

    // Execute the query with Apollo
    return this.$apolloClient.mutate(options).catch((error) => {
      // Call all error handlers
      // For some reason (either a bug in Nuxt Auth or Apollo), the error is not passed, unless wrapped in a setTimeout
      setTimeout(() => this.$auth.callOnError(error, { method: 'mutate' }), 0)

      // Throw error
      return Promise.reject(error)
    })
  }
}
